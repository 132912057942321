export const SET_FIRST_PASS = "SET_FIRST_PASS";
export const SET_SECOND_PASS = "SET_SECOND_PASS";
export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS";
export const CLEAR_SET_EMAIL_ADDRESS = "CLEAR_SET_EMAIL_ADDRESS";
export const VALID_TOKEN = "VALID_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const UPDATE_ERRORS = "UPDATE_ERRORS";
export const CARD_PARTIAL_REF = "CARD_PARTIAL_REF";
export const CARD_PARTIAL_CARD_NUMBER = "CARD_PARTIAL_CARD_NUMBER";
export const CARD_PARTIAL_MAGSTRIPE = "CARD_PARTIAL_MAGSTRIPE";
export const CARD_PARTIAL_CHANGE_SUBMISSION_METHOD =
  "CARD_PARTIAL_CHANGE_SUBMISSION_METHOD";
export const EXTOLE_REGISTRATION_COMPLETE = "EXTOLE_REGISTRATION_COMPLETE";
export const FIRE_EXTOLE_REGISTRATION = "FIRE_EXTOLE_REGISTRATION";
export const UPDATE_CUSTOM_TWILIO_MSG = "UPDATE_CUSTOM_TWILIO_MSG";
export const CHANGE_PAYPAL_STATUS = "CHANGE_PAYPAL_STATUS";
export const UPDATE_PAYPAL_PROFILE_INFO = "UPDATE_PAYPAL_PROFILE_INFO";
export const SET_BRAINTREE_TOKEN = "SET_BRAINTREE_TOKEN";
export const DELETE_BULK_SPREADSHEET_ERRORS = "DELETE_BULK_SPREADSHEET_ERRORS";
export const CARD_PARTIAL_BALANCE_MSG = "CARD_PARTIAL_BALANCE_MSG";
export const CARD_PARTIAL_CARD_MSG = "CARD_PARTIAL_CARD_MSG";
export const CARD_PARTIAL_PIN_MSG = "CARD_PARTIAL_PIN_MSG";
export const CARD_PARTIAL_UPDATE_MSG_STATE = "CARD_PARTIAL_UPDATE_MSG_STATE";
export const CARD_PARTIAL_RECEIVE_MAGSTRIPE = "CARD_PARTIAL_RECEIVE_MAGSTRIPE";
export const SHOW_BULK_NOTIFICATION = "SHOW_BULK_NOTIFICATION";
export const HIDE_BULK_NOTIFICATION = "HIDE_BULK_NOTIFICATION";
export const EMAIL_SENT = "EMAIL_SENT";
export const PASS_RESET = "PASS_RESET";
export const DISPLAY_ERROR_MSG = "DISPLAY_ERROR_MSG";
export const DISPLAY_LOADING = "DISPLAY_LOADING";
export const SET_BUY_ORDER_ID = "SET_BUY_ORDER_ID";
export const SET_BUY_ORDER_UID = "SET_BUY_ORDER_UID";
export const PUT_BITCOIN_INFO_IN_REDUX = "PUT_BITCOIN_INFO_IN_REDUX";
export const SET_RESET_PASSWORD_EMAIL = "SET_RESET_PASSWORD_EMAIL";
export const HIDE_CARDS_UNDER_CONSIDERATION_POPUP =
  "HIDE_CARDS_UNDER_CONSIDERATION_POPUP";
export const SET_NOTIFICATION_MSG = "SET_NOTIFICATION_MSG";
export const SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID =
  "SHOW_HOW_WOULD_YOU_LIKE_TO_GET_PAID";
export const HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID =
  "HIDE_HOW_WOULD_YOU_LIKE_TO_GET_PAID";
export const UPDATE_SIGN_IN_TXT = "UPDATE_SIGN_IN_TXT";
export const UPDATE_PAYPAL_SELL_INFO = "UPDATE_PAYPAL_SELL_INFO";
export const SEND_ECOMMERCE_INFO = "SEND_ECOMMERCE_INFO";
export const FADE_ERROR_INIT_FORGOT_PASSWORD =
  "FADE_ERROR_INIT_FORGOT_PASSWORD";
export const HIDE_ERROR_INIT_FORGOT_PASSWORD =
  "HIDE_ERROR_INIT_FORGOT_PASSWORD";
export const SHOW_ERROR_INIT_FORGOT_PASSWORD =
  "SHOW_ERROR_INIT_FORGOT_PASSWORD";
export const ADD_MESSAGES = "ADD_MESSAGES";
export const RESET_BUY = "RESET_BUY";
export const RESET_SELL = "RESET_SELL";
export const SET_CARDS_UNDER_CONSIDERATION = "SET_CARDS_UNDER_CONSIDERATION";
export const OPEN_DEDUCTION_CARD_IMG_MODAL = "OPEN_DEDUCTION_CARD_IMG_MODAL";
export const CLOSE_DEDUCTION_CARD_IMG_MODAL = "CLOSE_DEDUCTION_CARD_IMG_MODAL";
export const RECEIVE_SELL_MERCHANTS = "RECEIVE_SELL_MERCHANTS";
export const FILTER_BY_LETTER = "FILTER_BY_LETTER";
export const FILTER_BY_CATEGORY = "FILTER_BY_CATEGORY";
export const SHOW_ALL_MERCHANTS = "SHOW_ALL_MERCHANTS";
export const SET_SELL_PAYMENT_METHOD = "SET_SELL_PAYMENT_METHOD";
export const SET_BUY_PAYMENT_METHOD = "SET_BUY_PAYMENT_METHOD";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const PREV_PATH = "PREV_PATH";
export const SET_ACTIVE_CC_ROW = "SET_ACTIVE_CC_ROW";
export const SET_ACTIVE_ACH_ROW = "SET_ACTIVE_ACH_ROW";
export const SET_TWILIO_ATTEMPTS_EXCEEDED = "SET_TWILIO_ATTEMPTS_EXCEEDED";
export const RESET_TWILIO_ATTEMPTS_EXCEEDED = "RESET_TWILIO_ATTEMPTS_EXCEEDED";
export const SET_ACTIVE_PANEL = "SET_ACTIVE_PANEL";
export const RESET_ACTIVE_PANEL = "RESET_ACTIVE_PANEL";
export const SET_ACCT_SETTINGS_PANEL = "SET_ACCT_SETTINGS_PANEL";
export const RESET_ACCT_SETTINGS_PANEL = "RESET_ACCT_SETTINGS_PANEL";
export const OPEN_REMOVE_CREDIT_CARD_MODAL = "OPEN_REMOVE_CREDIT_CARD_MODAL";
export const CLOSE_REMOVE_CREDIT_CARD_MODAL = "CLOSE_REMOVE_CREDIT_CARD_MODAL";
export const OPEN_CHANGE_PASSWORD_MODAL = "OPEN_CHANGE_PASSWORD_MODAL";
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL";
export const OPEN_HELP_OPTIONS = "OPEN_HELP_OPTIONS";
export const CLOSE_HELP_OPTIONS = "CLOSE_HELP_OPTIONS";
export const SHOW_CARD_HELP_BUTTONS = "SHOW_CARD_HELP_BUTTONS";
export const CLOSE_CARD_HELP_BUTTONS = "CLOSE_CARD_HELP_BUTTONS";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_FIELD_TO_SORT = "SET_FIELD_TO_SORT";
export const SHOW_ADD_CARD_BOX = "SHOW_ADD_CARD_BOX";
export const HIDE_ADD_CARD_BOX = "HIDE_ADD_CARD_BOX";
export const KEEP_ADD_CARD_BOX_CLOSED = "KEEP_ADD_CARD_BOX_CLOSED";
export const SET_CHART_TO_RENDER = "SET_CHART_TO_RENDER";
export const CLEAR_CHART_TO_RENDER = "CLEAR_CHART_TO_RENDER";
export const SET_ACH_SELL_ACCOUNT_NUM = "SET_ACH_SELL_ACCOUNT_NUM";
export const SET_ACH_SELL_ROUTING_NUM = "SET_ACH_SELL_ROUTING_NUM";
export const SET_ACH_SELL_ACCOUNT_TYPE = "SET_ACH_SELL_ACCOUNT_TYPE";
export const DISPLAY_VALID_PIN = "DISPLAY_VALID_PIN";
export const ADD_CARD = "ADD_CARD";
export const ADD_CARD_TO_CART = "ADD_CARD_TO_CART";
export const DELETE_CARD = "DELETE_CARD";
export const DELETE_ALL_CARDS = "DELETE_ALL_CARDS";
export const SET_MERCHANT = "SET_MERCHANT";
export const SET_BALANCE = "SET_BALANCE";
export const SET_CARD_NUMBER = "SET_CARD_NUMBER";
export const SET_PIN = "SET_PIN";
export const UPDATE_CARDS = "UPDATE_CARDS";
export const CARD_PARTIAL_MERCHANT = "CARD_PARTIAL_MERCHANT";
export const CARD_PARTIAL_BALANCE = "CARD_PARTIAL_BALANCE";
export const CARD_PARTIAL_PIN = "CARD_PARTIAL_PIN";
export const CARD_PARTIAL_NUM = "CARD_PARTIAL_NUM";
export const CARD_PARTIAL_CHANGE_SUBMIT_TYPE =
  "CARD_PARTIAL_CHANGE_SUBMIT_TYPE";
export const CARD_PARTIAL_MERCH_MSG = "CARD_PARTIAL_MERCH_MSG";
export const UPDATE_MERCH_IN_CARD = "UPDATE_MERCH_IN_CARD";
export const UPDATE_CARD_BALANCE = "UPDATE_CARD_BALANCE";
export const UPDATE_CARD_PIN = "UPDATE_CARD_PIN";
export const UPDATE_CARD_NUMBER = "UPDATE_CARD_NUMBER";
export const UPDATE_THE_CARD_ERROR = "UPDATE_THE_CARD_ERROR";
export const UPDATE_THE_CARD_PIN_ERROR = "UPDATE_THE_CARD_PIN_ERROR";
export const CARD_PARTIAL_CLEAR = "CARD_PARTIAL_CLEAR";
export const SET_SELL_CART_ID = "SET_SELL_CART_ID";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_VERIFY_ID = "SET_VERIFY_ID";
export const UPDATE_PASS_CODE = "UPDATE_PASS_CODE";
export const COMPLETE_ORDER_SELL = "COMPLETE_ORDER_SELL";
export const UPDATE_ACCOUNT_STATUS = "UPDATE_ACCOUNT_STATUS";
export const SHOW_ACCOUNT_LOCKED = "SHOW_ACCOUNT_LOCKED";
export const HIDE_ACCOUNT_LOCKED = "HIDE_ACCOUNT_LOCKED";
export const SORT_DROP_DOWN = "SORT_DROP_DOWN";
export const ALL_PASS = "ALL_PASS";
export const DISPLAY_VALID_CARD_NUM = "DISPLAY_VALID_CARD_NUM";
export const USER_INPUT_MERCHANT = "USER_INPUT_MERCHANT";
export const CLEAR_USER_INPUT_MERCHANT = "CLEAR_USER_INPUT_MERCHANT";
export const UPDATE_AUTOCOMPLETE_TEXT = "UPDATE_AUTOCOMPLETE_TEXT";
export const CLEAR_AUTOCOMPLETE_TEXT = "CLEAR_AUTOCOMPLETE_TEXT";
export const SET_PW = "SET_PW";
export const CLEAR_SET_PW = "CLEAR_SET_PW";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_LAST_NAME = "SET_LAST_NAME";
export const SET_STREET = "SET_STREET";
export const SET_CITY = "SET_CITY";
export const SELECT_STATE = "SELECT_STATE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_ZIPCODE = "SET_ZIPCODE";
export const SET_PHONE = "SET_PHONE";
export const SET_CREDIT_CARD = "SET_CREDIT_CARD";
export const SELECT_MONTH = "SELECT_MONTH";
export const SET_CCV = "SET_CCV";
export const SET_CUSTOMER_PROFILE = "SET_CUSTOMER_PROFILE";
export const CUSTOMER_PROFILE_THUNK_RUNNING = "CUSTOMER_PROFILE_THUNK_RUNNING";
export const SELECT_YEAR = "SELECT_YEAR";
export const SET_TOKEN = "SET_TOKEN";
export const SET_SIGNIN_EMAIL = "SET_SIGNIN_EMAIL";
export const CLEAR_SET_SIGNIN_EMAIL = "CLEAR_SET_SIGNIN_EMAIL";
export const SET_SIGNIN_PASSWORD = "SET_SIGNIN_PASSWORD";
export const CLEAR_SET_SIGNIN_PASSWORD = "CLEAR_SET_SIGNIN_PASSWORD";
export const LOGOUT = "LOGOUT";
export const LOGOUT_MARK_THUNK_RUNNING = "LOGOUT_MARK_THUNK_RUNNING";
export const LOGOUT_CLEAR_THUNK_RUNNING = "LOGOUT_CLEAR_THUNK_RUNNING";
export const HIDE_FORGOT_PASSWORD = "HIDE_FORGOT_PASSWORD";
export const DISPLAY_FORGOT_PASSWORD = "DISPLAY_FORGOT_PASSWORD";
export const SET_FORGOT_PASS_EMAIL = "SET_FORGOT_PASS_EMAIL";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SESSION_RENDER_ASSETS = "SESSION_RENDER_ASSETS";
export const UPDATE_ATTEMPTS = "UPDATE_ATTEMPTS";
export const MAKE_ANOTHER_PASSCODE_ATTEMPT = "MAKE_ANOTHER_PASSCODE_ATTEMPT";
export const RESET_EXPIRE = "RESET_EXPIRE";
export const DISPLAY_CUSTOMER_INFO_ERRORS = "DISPLAY_CUSTOMER_INFO_ERRORS";
export const INVALID_CARD_ERROR = "INVALID_CARD_ERROR";
export const INVALID_PIN_ERROR = "INVALID_PIN_ERROR";
export const DISPLAY_SIGNIN_EMAIL_ERROR = "DISPLAY_SIGNIN_EMAIL_ERROR";
export const DISPLAY_SIGNIN_PASS_ERROR = "DISPLAY_SIGNIN_PASS_ERROR";
export const DISPLAY_INVALID_MATCH = "DISPLAY_INVALID_MATCH";
export const DISPLAY_MATCH = "DISPLAY_MATCH";
export const DISPLAY_CARD_ERROR_PARTIAL = "DISPLAY_CARD_ERROR_PARTIAL";
export const DISPLAY_MAX_ERROR_PARTIAL = "DISPLAY_MAX_ERROR_PARTIAL";
export const DISPLAY_MIN_ERROR_PARTIAL = "DISPLAY_MIN_ERROR_PARTIAL";
export const DISPLAY_MERCHANT_ERROR_PARTIAL = "DISPLAY_MERCHANT_ERROR_PARTIAL";
export const DISPLAY_NO_ERROR_PARTIAL = "DISPLAY_NO_ERROR_PARTIAL";
export const DISPLAY_MAIN_PAYMENT_ERROR = "DISPLAY_MAIN_PAYMENT_ERROR";
export const HIDE_SIGNIN_EMAIL_ERROR = "HIDE_SIGNIN_EMAIL_ERROR";
export const HIDE_SIGNIN_PASS_ERROR = "HIDE_SIGNIN_PASS_ERROR";
export const HIDE_INVALID_CARD_ERROR = "HIDE_INVALID_CARD_ERROR";
export const HIDE_INVALID_PIN_ERROR = "HIDE_INVALID_PIN_ERROR";
export const DISPLAY_VALID_BALANCE = "DISPLAY_VALID_BALANCE";
export const DISPLAY_MAX_BALANCE_ERROR = "DISPLAY_MAX_BALANCE_ERROR";
export const DISPLAY_MIN_BALANCE_ERROR = "DISPLAY_MIN_BALANCE_ERROR";
export const DISPLAY_NO_BALANCE_ERROR = "DISPLAY_NO_BALANCE_ERROR";
export const DISPLAY_ACCEPT_TERMS_ERROR = "DISPLAY_ACCEPT_TERMS_ERROR";
export const HIDE_ACCEPT_TERMS_ERROR = "HIDE_ACCEPT_TERMS_ERROR";
export const DISPLAY_CARD_BALANCE_FORMAT_ERROR =
  "DISPLAY_CARD_BALANCE_FORMAT_ERROR";
export const CLEAR_UPDATE_CARD_SERVER_ERROR = "CLEAR_UPDATE_CARD_SERVER_ERROR";
export const SET_UPDATE_CARD_SERVER_ERROR = "SET_UPDATE_CARD_SERVER_ERROR";
export const CLEAR_CARD_AND_PIN_ENTRY = "CLEAR_CARD_AND_PIN_ENTRY";
export const DISPLAY_FIRST_NAME_ERROR = "DISPLAY_FIRST_NAME_ERROR";
export const DISPLAY_LAST_NAME_ERROR = "DISPLAY_LAST_NAME_ERROR";
export const DISPLAY_STREET_ERROR = "DISPLAY_STREET_ERROR";
export const DISPLAY_STATE_ERROR = "DISPLAY_STATE_ERROR";
export const DISPLAY_EMAIL_ERROR = "DISPLAY_EMAIL_ERROR";
export const DISPLAY_PHONE_ERROR = "DISPLAY_PHONE_ERROR";
export const DISPLAY_CREDIT_CARD_NUM_ERROR = "DISPLAY_CREDIT_CARD_NUM_ERROR";
export const DISPLAY_MONTH_ERROR = "DISPLAY_MONTH_ERROR";
export const DISPLAY_YEAR_ERROR = "DISPLAY_YEAR_ERROR";
export const DISPLAY_CCV_ERROR = "DISPLAY_CCV_ERROR";
export const DISPLAY_ZIP_ERROR = "DISPLAY_ZIP_ERROR";
export const DISPLAY_CITY_ERROR = "DISPLAY_CITY_ERROR";
export const DISPLAY_CHANGE_PHONE_ERROR = "DISPLAY_CHANGE_PHONE_ERROR";
export const HIDE_CHANGE_PHONE_ERROR = "HIDE_CHANGE_PHONE_ERROR";
export const HIDE_FIRST_NAME_ERROR = "HIDE_FIRST_NAME_ERROR";
export const HIDE_LAST_NAME_ERROR = "HIDE_LAST_NAME_ERROR";
export const HIDE_STREET_ERROR = "HIDE_STREET_ERROR";
export const HIDE_STATE_ERROR = "HIDE_STATE_ERROR";
export const HIDE_ZIP_ERROR = "HIDE_ZIP_ERROR";
export const HIDE_CITY_ERROR = "HIDE_CITY_ERROR";
export const HIDE_EMAIL_ERROR = "HIDE_EMAIL_ERROR";
export const HIDE_PHONE_ERROR = "HIDE_PHONE_ERROR";
export const HIDE_CREDIT_CARD_NUM_ERROR = "HIDE_CREDIT_CARD_NUM_ERROR";
export const HIDE_MONTH_ERROR = "HIDE_MONTH_ERROR";
export const HIDE_YEAR_ERROR = "HIDE_YEAR_ERROR";
export const HIDE_CCV_ERROR = "HIDE_CCV_ERROR";
export const HIDE_ACH_ACCOUNT_NUM_ERROR = "HIDE_ACH_ACCOUNT_NUM_ERROR";
export const HIDE_ACH_ROUTING_NUM_ERROR = "HIDE_ACH_ROUTING_NUM_ERROR";
export const HIDE_ACH_ACCOUNT_TYPE_ERROR = "HIDE_ACH_ACCOUNT_TYPE_ERROR";
export const HIDE_MAIN_PAYMENT_ERROR = "HIDE_MAIN_PAYMENT_ERROR";
export const DISPLAY_RECAPTCHA = "DISPLAY_RECAPTCHA";
export const SET_RECAPTCHA = "SET_RECAPTCHA";
export const SET_CHANGE_PHONE_TXT = "SET_CHANGE_PHONE_TXT";
export const POPUPS_SHOW_DONATION_INFO = "POPUPS_SHOW_DONATION_INFO";
export const POPUPS_HIDE_DONATION_INFO = "POPUPS_HIDE_DONATION_INFO";
export const POPUPS_OPEN_COLLEGE_VIDEO = "POPUPS_OPEN_COLLEGE_VIDEO";
export const POPUPS_CLOSE_COLLEGE_VIDEO = "POPUPS_CLOSE_COLLEGE_VIDEO";
export const SHOW_SELL_PAYMENT_POPUP = "SHOW_SELL_PAYMENT_POPUP";
export const HIDE_SELL_PAYMENT_POPUP = "HIDE_SELL_PAYMENT_POPUP";
export const SHOW_CHOOSE_CHECKOUT_POPUP = "SHOW_CHOOSE_CHECKOUT_POPUP";
export const HIDE_CHOOSE_CHECKOUT_POPUP = "HIDE_CHOOSE_CHECKOUT_POPUP";
export const SHOW_SESSION_EXPIRED = "SHOW_SESSION_EXPIRED";
export const HIDE_SESSION_EXPIRED = "HIDE_SESSION_EXPIRED";
export const TOGGLE_CONTACT_US = "TOGGLE_CONTACT_US";
export const TOGGLE_BULK_SELL_LOGIN_ERROR = "TOGGLE_BULK_SELL_LOGIN_ERROR";
export const TOGGLE_VERIFICATION_BOX = "TOGGLE_VERIFICATION_BOX";
export const OPEN_ERROR_BOX = "OPEN_ERROR_BOX";
export const CLOSE_ERROR_BOX = "TOGGLE_ERROR_BOX";
export const OPEN_ATTEMPTS_EXCEEDED_BOX = "OPEN_ATTEMPTS_EXCEEDED_BOX";
export const CLOSE_ATTEMPTS_EXCEEDED_BOX = "CLOSE_ATTEMPTS_EXCEEDED_BOX";
export const TOGGLE_CHOOSE_VERIFICATION = "TOGGLE_CHOOSE_VERIFICATION";
export const SHOW_CHANGE_PHONE = "SHOW_CHANGE_PHONE";
export const HIDE_CHANGE_PHONE = "HIDE_CHANGE_PHONE";
export const SHOW_SESSION_EXPIRE_WARNING = "SHOW_SESSION_EXPIRE_WARNING";
export const HIDE_SESSION_EXPIRE_WARNING = "HIDE_SESSION_EXPIRE_WARNING";
export const UPDATE_INVALID_CARD_MSG = "UPDATE_INVALID_CARD_MSG";
export const SHOW_TWILIO_POPUP = "SHOW_TWILIO_POPUP";
export const SET_CLAIM_TYPE = "SET_CLAIM_TYPE";
export const SET_CLAIM_AMOUNT = "SET_CLAIM_AMOUNT";
export const SET_CLAIM_AMOUNT_TO_REFUND = "SET_CLAIM_AMOUNT_TO_REFUND";
export const SET_ORDER_ASSISTANCE_API_ERROR = "SET_ORDER_ASSISTANCE_API_ERROR";
export const SET_ORDER_SUPPORT_INFO = "SET_ORDER_SUPPORT_INFO";
export const OPEN_EDIT_PROFILE_MODAL = "OPEN_EDIT_PROFILE_MODAL";
export const CLOSE_EDIT_PROFILE_MODAL = "CLOSE_EDIT_PROFILE_MODAL";
export const OPEN_SUPPORT_MODAL = "OPEN_SUPPORT_MODAL";
export const CLOSE_SUPPORT_MODAL = "CLOSE_SUPPORT_MODAL";
export const OPEN_SUPPORT_CARD_LEVEL_MODAL = "OPEN_SUPPORT_CARD_LEVEL_MODAL";
export const CLOSE_SUPPORT_CARD_LEVEL_MODAL = "CLOSE_SUPPORT_CARD_LEVEL_MODAL";
export const OPEN_CANCEL_SELL_ORDER_MODAL = "OPEN_CANCEL_SELL_ORDER_MODAL";
export const CLOSE_CANCEL_SELL_ORDER_MODAL = "CLOSE_CANCEL_SELL_ORDER_MODAL";
export const OPEN_PAYMENT_DETAILS_MODAL = "OPEN_PAYMENT_DETAILS_MODAL";
export const CLOSE_PAYMENT_DETAILS_MODAL = "CLOSE_PAYMENT_DETAILS_MODAL";
export const CLOSE_SHARE_A_SALE_MODAL = "CLOSE_SHARE_A_SALE_MODAL";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const DENY_TERMS = "DENY_TERMS";
export const SHOW_LOADING = "SHOW_LOADING";
export const FADE_PASS_RESET = "FADE_PASS_RESET";
export const SHRINK_BOX = "SHRINK_BOX";
export const TOGGLE_DROP_DOWN = "TOGGLE_DROP_DOWN";
export const SHOW_DETAILS_ORDER = "SHOW_DETAILS_ORDER";
export const HIDE_DETAILS_ORDER = "HIDE_DETAILS_ORDER";
export const TOGGLE_DETAILS_ORDER = "TOGGLE_DETAILS_ORDER";
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_LOCAL_SPINNER = "SHOW_LOCAL_SPINNER";
export const HIDE_LOCAL_SPINNER = "HIDE_LOCAL_SPINNER";
export const SET_SLICK_DEALS_SDTID = "SET_SLICK_DEALS_SDTID";
export const RESET_SELL_ACH = "RESET_SELL_ACH";
export const UPDATE_SAVE_ACH_SELL = "UPDATE_SAVE_ACH_SELL";
export const SET_CREDIT_CARD_TO_REMOVE = "SET_CREDIT_CARD_TO_REMOVE";
export const SET_PAYMENT_OPTIONS = "SET_PAYMENT_OPTIONS";
export const SHOW_ADDITIONAL_PAYMENT_OPTIONS =
  "SHOW_ADDITIONAL_PAYMENT_OPTIONS";
export const SELECT_PAYMENT_OPTION = "SELECT_PAYMENT_OPTION";
export const HIDE_ADDITIONAL_PAYMENT_OPTIONS =
  "HIDE_ADDITIONAL_PAYMENT_OPTIONS";
export const SET_CUSTOMER_BANK_ACCOUNTS = "SET_CUSTOMER_BANK_ACCOUNTS";
export const SHOW_SPREADSHEET_UPLOAD_ERROR = "SHOW_SPREADSHEET_UPLOAD_ERROR";
export const HIDE_SPREADSHEET_UPLOAD_ERROR = "HIDE_SPREADSHEET_UPLOAD_ERROR";
export const FILTER_BY_LETTER_NEW = "FILTER_BY_LETTER_NEW";
export const SHOW_ALL_MERCHANTS_NEW = "SHOW_ALL_MERCHANTS_NEW";
export const HIDE_SEARCH = "HIDE_SEARCH";
export const SHOW_SEARCH = "SHOW_SEARCH";
export const SHOW_DROPDOWN = "SHOW_DROPDOWN";
export const HIDE_DROPDOWN = "HIDE_DROPDOWN";
export const SET_PAYOUT = "SET_PAYOUT";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const RESET_SEARCH_TEXT = "RESET_SEARCH_TEXT";
export const UPDATE_ORDER_STATUS_LAST_NAME = "UPDATE_ORDER_STATUS_LAST_NAME";
export const UPDATE_ORDER_STATUS_ID = "UPDATE_ORDER_STATUS_ID";
export const FADE_IN_CARD_ADDED = "FADE_IN_CARD_ADDED";
export const FADE_OUT_CARD_ADDED = "FADE_OUT_CARD_ADDED";
export const HIDE_CARD_ADDED = "HIDE_CARD_ADDED";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const CHANGE_CURRENT_CARD = "CHANGE_CURRENT_CARD";
export const SET_SIGN_IN_ERRORS = "SET_SIGN_IN_ERRORS";
export const CHECKOUT_ORDER_SELL_SETCARDS = "CHECKOUT_ORDER_SELL_SETCARDS";
export const HIDE_TWILIO_POPUP = "HIDE_TWILIO_POPUP";
export const SHOW_SELL_VERIFICATION_SAME_AS_SHIPPING =
  "SHOW_SELL_VERIFICATION_SAME_AS_SHIPPING";
export const HIDE_SELL_VERIFICATION_SAME_AS_SHIPPING =
  "HIDE_SELL_VERIFICATION_SAME_AS_SHIPPING";
export const DISPLAY_SELL_SHIPPING_ERRORS = "DISPLAY_SELL_SHIPPING_ERRORS";
export const DISPLAY_SELL_VERIFICATION_ERRORS =
  "DISPLAY_SELL_VERIFICATION_ERRORS";
export const USE_UNIQUE_SELL_BILLING_ADDRESS =
  "USE_UNIQUE_SELL_BILLING_ADDRESS";
export const MATCH_SELL_SHIPPING_TO_SELL_VERIFICATION =
  "MATCH_SELL_SHIPPING_TO_SELL_VERIFICATION";
export const MATCH_CUSTOMER_INFO_TO_SELL_VERIFICATION =
  "MATCH_CUSTOMER_INFO_TO_SELL_VERIFICATION";
export const MATCH_CUSTOMER_INFO_TO_SELL_SHIPPING =
  "MATCH_CUSTOMER_INFO_TO_SELL_SHIPPING";
export const SELL_VERIFICATION_SET_FIRST_NAME =
  "SELL_VERIFICATION_SET_FIRST_NAME";
export const SELL_VERIFICATION_SET_LAST_NAME =
  "SELL_VERIFICATION_SET_LAST_NAME";
export const SELL_VERIFICATION_SET_STREET = "SELL_VERIFICATION_SET_STREET";
export const SELL_VERIFICATION_SET_STREET2 = "SELL_VERIFICATION_SET_STREET2";
export const SELL_VERIFICATION_SET_CITY = "SELL_VERIFICATION_SET_CITY";
export const SELL_VERIFICATION_SET_ZIPCODE = "SELL_VERIFICATION_SET_ZIPCODE";
export const SELL_VERIFICATION_SET_STATE = "SELL_VERIFICATION_SET_STATE";
export const SELL_VERIFICATION_SET_EMAIL = "SELL_VERIFICATION_SET_EMAIL";
export const SELL_VERIFICATION_SET_PHONE = "SELL_VERIFICATION_SET_PHONE";
export const SELL_VERIFICATION_SET_PHONE_ERROR =
  "SELL_VERIFICATION_SET_PHONE_ERROR";
export const SELL_VERIFICATION_SET_EMAIL_ERROR =
  "SELL_VERIFICATION_SET_EMAIL_ERROR";
export const DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR =
  "DISPLAY_SELL_VERIFICATION_FIRST_NAME_ERROR";
export const DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR =
  "DISPLAY_SELL_VERIFICATION_LAST_NAME_ERROR";
export const DISPLAY_SELL_VERIFICATION_STREET_ERROR =
  "DISPLAY_SELL_VERIFICATION_STREET_ERROR";
export const DISPLAY_SELL_VERIFICATION_STREET2_ERROR =
  "DISPLAY_SELL_VERIFICATION_STREET2_ERROR";
export const DISPLAY_SELL_VERIFICATION_CITY_ERROR =
  "DISPLAY_SELL_VERIFICATION_CITY_ERROR";
export const DISPLAY_SELL_VERIFICATION_ZIP_ERROR =
  "DISPLAY_SELL_VERIFICATION_ZIP_ERROR";
export const DISPLAY_SELL_VERIFICATION_STATE_ERROR =
  "DISPLAY_SELL_VERIFICATION_STATE_ERROR";
export const HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR =
  "HIDE_SELL_VERIFICATION_FIRST_NAME_ERROR";
export const HIDE_SELL_VERIFICATION_LAST_NAME_ERROR =
  "HIDE_SELL_VERIFICATION_LAST_NAME_ERROR";
export const HIDE_SELL_VERIFICATION_STREET_ERROR =
  "HIDE_SELL_VERIFICATION_STREET_ERROR";
export const HIDE_SELL_VERIFICATION_CITY_ERROR =
  "HIDE_SELL_VERIFICATION_CITY_ERROR";
export const HIDE_SELL_VERIFICATION_ZIP_ERROR =
  "HIDE_SELL_VERIFICATION_ZIP_ERROR";
export const HIDE_SELL_VERIFICATION_STATE_ERROR =
  "HIDE_SELL_VERIFICATION_STATE_ERROR";
export const HIDE_SELL_VERIFICATION_PHONE_ERROR =
  "HIDE_SELL_VERIFICATION_PHONE_ERROR";
export const HIDE_SELL_VERIFICATION_EMAIL_ERROR =
  "HIDE_SELL_VERIFICATION_EMAIL_ERROR";
export const HIDE_SELL_VERIFICATION_STREET2_ERROR =
  "HIDE_SELL_VERIFICATION_STREET2_ERROR";
export const HIDE_TLO_ERROR_PHONE_MODAL = "HIDE_TLO_ERROR_PHONE_MODAL";
export const SHOW_TLO_ERROR_PHONE_MODAL = "SHOW_TLO_ERROR_PHONE_MODAL";
export const HIDE_TLO_VERIFICATION_INIT = "HIDE_TLO_VERIFICATION_INIT";
export const SHOW_TLO_VERIFICATION_INIT = "SHOW_TLO_VERIFICATION_INIT";
export const UPDATE_TLO_VALIDATION_STATUS = "UPDATE_TLO_VALIDATION_STATUS";
export const SET_TLO_VALIDATION_PHONE_NUMBERS =
  "SET_TLO_VALIDATION_PHONE_NUMBERS";
export const HIDE_TLO_VERIFICATION_EXPIRED = "HIDE_TLO_VERIFICATION_EXPIRED";
export const SHOW_TLO_VERIFICATION_EXPIRED = "SHOW_TLO_VERIFICATION_EXPIRED";
export const HIDE_TLO_VERIFICATION_COMPLETED =
  "HIDE_TLO_VERIFICATION_COMPLETED";
export const SHOW_TLO_VERIFICATION_COMPLETED =
  "SHOW_TLO_VERIFICATION_COMPLETED";
export const SELECT_TLO_PHONE_NUMBER = "SELECT_TLO_PHONE_NUMBER";
export const SET_TLO_VERIFICATION_TYPE = "SET_TLO_VERIFICATION_TYPE";
export const HIDE_TLO_VERIFICATION_ENTER = "HIDE_TLO_VERIFICATION_ENTER";
export const SHOW_TLO_VERIFICATION_ENTER = "SHOW_TLO_VERIFICATION_ENTER";
export const SET_TLO_ENTER_CODE = "SET_TLO_ENTER_CODE";
export const CLEAR_TLO_ENTER_CODE = "CLEAR_TLO_ENTER_CODE";
export const SET_TLO_ENTERED_CODE_ERROR = "SET_TLO_ENTERED_CODE_ERROR";
export const TLO_RESET = "TLO_RESET";
export const SET_ERROR = "SET_ERROR";
export const SET_LIMITS = "SET_LIMITS";
export const DISPLAY_STREET2_ERROR = "DISPLAY_STREET2_ERROR";
export const HIDE_STREET2_ERROR = "HIDE_STREET2_ERROR";
export const SET_STREET2 = "SET_STREET2";
export const LOAD_HARD_CODED_TEST_DATA = "LOAD_HARD_CODED_TEST_DATA";
export const RESET = "RESET";
export const ANYCARD_ORDER_TOTAL_EXCEEDED = "ANYCARD_ORDER_TOTAL_EXCEEDED";
export const SOUNDS_GOOD_CONTINUE_DISABLED = "SOUNDS_GOOD_CONTINUE_DISABLED";
export const ORDER_TOTAL_MIN_REQUIRED = "ORDER_TOTAL_MIN_REQUIRED";
export const ORDER_TOTAL_MAX_EXCEEDED = "ORDER_TOTAL_MAX_EXCEEDED";
export const SELL_ORDER_SUCCESS = "SELL_ORDER_SUCCESS";
