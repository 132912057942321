import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";

import { defaultAction } from "../../actions/defaultAction";
import { validBalance } from "../../utils/validators/stepOneSell_v2";
import { merchantValuesSelector } from "../../selectors";
import { score } from "../../utils/fuzzySearch";

import {
  CARD_PARTIAL_MERCHANT,
  CARD_PARTIAL_BALANCE,
  DISPLAY_NO_ERROR_PARTIAL,
} from "../../constants/actionTypes";

const InputCard = ({
  offerMerchantDropDownPlaceHolder,
  offerBalanceInputPlaceHolder,
  cardPartial,
}) => {
  const dispatch = useDispatch();
  const merchantOptions = useSelector((state) => merchantValuesSelector(state));
  const cardPartialBalance = (e) => {
    dispatch(defaultAction(CARD_PARTIAL_BALANCE, e.target.value));
  };
  const checkBalance = () => validBalance(cardPartial, dispatch);
  const submitCardPartialMerchant = (merchant) => {
    dispatch(defaultAction(DISPLAY_NO_ERROR_PARTIAL));
    Array.isArray(merchant) && !merchant.length
      ? dispatch(defaultAction(CARD_PARTIAL_MERCHANT, ""))
      : dispatch(defaultAction(CARD_PARTIAL_MERCHANT, merchant));
  };
  const filterOption = (merchant, text) =>
    !!text ? score(merchant.data, text) : true;

  return (
    <>
      <Select
        filterOption={filterOption}
        placeholder={offerMerchantDropDownPlaceHolder}
        value={cardPartial.selectedMerchant}
        onChange={submitCardPartialMerchant}
        options={merchantOptions}
        isClearable={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#f6f6f6",
            primary: " rgb(215, 215, 215)",
          },
        })}
        styles={{
          indicatorSeparator: (styles) => ({ ...styles, width: "0px" }),
          menu: (styles) => ({
            ...styles,
            marginTop: "0px",
          }),
          menuList: (styles) => ({
            ...styles,
            maxHeight: "200px",
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "#ccc",
          }),
        }}
      />
      <div className={cardPartial.displayMerchantError ? "errorMsg" : "hide"}>
        Please select a merchant
      </div>

      <input
        onBlur={checkBalance}
        value={cardPartial.balance}
        onChange={cardPartialBalance}
        type="text"
        className={"form-control"}
        placeholder={offerBalanceInputPlaceHolder}
      />
      <span className={cardPartial.displayBalanceError ? "errorMsg" : "hide"}>
        Please enter a card value!
      </span>
      <span className={cardPartial.displayMaxError ? "errorMsg" : "hide"}>
        $
        {cardPartial.selectedMerchant &&
          cardPartial.selectedMerchant.limit &&
          cardPartial.selectedMerchant.limit.max}
        &nbsp; is the maximum allowed balance
      </span>
      <span className={cardPartial.displayMinError ? "errorMsg" : "hide"}>
        $
        {cardPartial.selectedMerchant &&
          cardPartial.selectedMerchant.limit &&
          cardPartial.selectedMerchant.limit.min}
        &nbsp; is the minimum allowed balance
      </span>
      <span
        className={
          cardPartial.displayCardBalanceFormatError ? "errorMsg" : "hide"
        }
      >
        Invalid balance
      </span>
    </>
  );
};

InputCard.propTypes = {
  offerMerchantDropDownPlaceHolder: PropTypes.string.isRequired,
  offerBalanceInputPlaceHolder: PropTypes.string.isRequired,
  cardPartial: PropTypes.object.isRequired,
};

export default InputCard;
