import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import CCA from "./a";

const Copyright = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    display: inline-block;
    font-size: 14px;
    line-height: 1.29;
    color: ${({ textColor }) => textColor};
    text-align: center;
  }
`;

const A = styled(CCA)`
  color: ${({ linkColor }) => linkColor};
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
`;

const Dot = styled.span`
  padding: 0 6px;
  color: ${({ linkColor }) => linkColor};
`;

const WidgetCopyright = ({ textColor, linkColor }) => {
  const config = useSelector((state) => state.config);

  return (
    <>
      {config.variant === "widget" && (
        <Copyright textColor={textColor}>
          <span>
            &copy; {new Date().getFullYear()} CardCash <br />
            <A linkColor={linkColor} href={config.giftCardTerms}>
              {config.termsAndConditionsBuilder(config)}
            </A>
            {
              config.privacyPolicyUrl ? (
                <>
                  <Dot linkColor={linkColor}>•</Dot>
                  <A linkColor={linkColor} href={config.privacyPolicyUrl}>
                    Privacy Policy
                  </A>
                </>
              ) : null
            }
          </span>
        </Copyright>
      )}
    </>
  );
};

WidgetCopyright.propTypes = {
  textColor: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default WidgetCopyright;
