import { configBuilder } from "ccui-framework";
import {
  commonConfig,
  largeText,
  smallText,
} from "../../../src/config/partner";

const Config = {
  sheetz: {
    ...commonConfig,
    layoutVersion: 1,
    reduxLogger: false,
    // Verification
    verificationType: "tlo",

    partnerBrand: "Sheetz",
    partnerURL: "http://www.sheetz.com",

    partnerEmail: "support@cardcash.com",
    contactUs_phoneNumber: "1-800-227-4214",

    giftCardTerms: "https://www.sheetz.com/legal/terms",
    paymentType: "eGift Card",
    aOrAn: "a",
    twitterURL: "https://twitter.com/sheetz",
    facebookURL: "https://www.facebook.com/sheetz",
    youTubeURL: "https://www.youtube.com/user/sheetzcorp",
    instagramURL: "https://www.instagram.com/sheetz/",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: null,
    googleAnalytics: "UA-71288742-1",
    x_cc_app: "pvMO09XvN",
    baseURL: "https://production-api.cardcash.com/",
    iconThreeText: "Receive an eGift Card",
    headerTextPartOne: "Exchange Gift Cards for a",
    headerTextPartTwo: " Sheetz eGift Card",
    secHeaderText: "Over 200 Merchants Accepted!",
    bottomLinkText: "Redeem your eGift Card at",
    headerContactLinkText: "Gift Card Help",
    sellBtnText: "GET MY EGIFT CARD",
    offerButtonTxt: "GET OFFER",
    totalText: "Total Offer",
    showTermsConditions: true,
    headerContained: true,
    headerMenuItemImage2: null,
    headerMenuItemImage3: null,
    headerLogoBrand:
      "https://cdn.cardcash.com/website/partners/sheetz/logo.png",
    headerLogoCC:
      "https://cdn.cardcash.com/website/partners/common/card_cash_logo.png",
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/sheetz/logo.png",
    headerLogoCCIcon:
      "https://cdn.cardcash.com/website/partners/common/card_cash_logo_icon.png",
    stepsVersion: 2,
    stepsHideBottomOn: [],

    detailStepOneText: {
      description: [
        largeText("Exchange Gift Cards"),
        largeText("for a Sheetz gift Card"),
        smallText("Over 200 Merchants Accepted"),
      ],

      header: "Get Offer",
      text: "Enter merchant name and card balance for a quick exchange offer. In seconds, see how much you can get in exchange for your gift card.",
    },
    detailStepTwoText: {
      description: [largeText("Enter Your Card"), largeText("and Pin Numbers")],

      header: "Enter Information",
      text: "Enter card number and pin and some basic customer information. Once your order is placed, your card balance will be verified behind the scenes to ensure the fastest and most secure process possible.",
    },
    detailStepThreeText: {
      description: [
        largeText("Enter Your"),
        largeText("Personal"),
        largeText("Information"),
      ],

      header: "Receive a Sheetz eGift Card",
      iconText: "Receive an eGift Card",
      cardImage:
        "https://cdn.cardcash.com/website/partners/sheetz/sheetz_card.png",
      text: "Your eGift Card will be emailed to you in a printable format once your order has been approved. Approval time can take up to 1 business day.",
    },
    cardCashLogoFirst: true,
  },

  "test-sheetz": {
    devMode: "integration",
    googleAnalytics: "",
    x_cc_app: "s45QEjLtf",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },

  localhost: {
    devMode: "development",
    googleAnalytics: "",
    x_cc_app: "s45QEjLtf",
    baseURL: "https://integration-api.cardcash.com/",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("sheetz", Config);
