import React from "react";
import validateSellCard from "../../utils/validators/validateSellCard";
import { CARD_NUMBER_REGEX, CARD_PIN_REGEX } from "../../constants/regex";
import debounce from "../../utils/debounce";

import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

const Card = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;

  ${mediaPhone} {
    flex-direction: column;
  }
  ${mediaDesktop} {
    flex-direction: row;
  }
`;

const Num = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  ${mediaDesktop} {
    margin-right: 1rem;
    flex: 1;
  }
  ${mediaPhone} {
    flex: 2;
  }
`;

const Pin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;
`;

const DesktopErrors = styled.div`
  ${mediaPhone} {
    display: none;
  }

  ${mediaDesktop} {
    height: 20px;
    margin-right: 3rem;
    flex: 1;
  }
`;

const MobileErrors = styled.div`
  ${mediaDesktop} {
    display: none;
  }
`;

const Icon = styled.span`
  right: 1.2rem;
  position: relative;
  color: ${({ theme, active }) => (active ? theme.primary_color : "#1F2021")};

  ${mediaPhone} {
    bottom: 0.5rem;
  }
`;

export default class StepTwoCart extends React.Component {
  constructor(props) {
    super(props);
    this.updateMerchNum = debounce(this.updateMerchNum, 400);
    this.updateMerchPin = debounce(this.updateMerchPin, 400);

    this.state = {
      cardNumActive: false,
      pinActive: false,
    };
  }

  componentDidMount() {
    const {
      cards,
      cardID,
      clearUpdateCardServerError,
      updateCardNum,
      updatePin,
    } = this.props;
    const { number: cardNumFromServer, pin: pinNumFromServer } = cards[cardID];
    if (cardNumFromServer || pinNumFromServer) {
      clearUpdateCardServerError(cardID);
      updateCardNum(cardID, cardNumFromServer);
      updatePin(cardID, pinNumFromServer);
    }
    setTimeout(this.validate, 0);
  }

  componentWillUnmount() {
    const { clearCardAndPin, cardID } = this.props;
    clearCardAndPin(cardID);
  }

  updateMerchNum(cardNumber) {
    const { cardID, updateCardNum } = this.props;
    updateCardNum(cardID, cardNumber);
    this.validate();
  }

  updateMerchPin(cardPin) {
    const { cardID, updatePin } = this.props;
    updatePin(cardID, cardPin);
    this.validate();
  }

  escapeSpecialChar(e) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
  }

  validate = () => {
    const {
      validation = "",
      cards,
      cardID,
      cartSellID,
      sendCardAndPinToServer,
      clearUpdateCardServerError,
      displayMatch,
      displayInvalidMatch,
    } = this.props;
    const { cardNumEnter, pinNumEnter } = cards[cardID];
    let regexError = false;

    const { error: validationError } = validateSellCard(
      cardNumEnter,
      pinNumEnter,
      validation
    );

    if (cardNumEnter && !CARD_NUMBER_REGEX.test(cardNumEnter)) {
      regexError = true;
    }

    if (pinNumEnter && !CARD_PIN_REGEX.test(pinNumEnter)) {
      regexError = true;
    }

    if (validationError || regexError) {
      displayInvalidMatch(cardID);
    } else {
      displayMatch(cardID);
      if (!cardNumEnter && !pinNumEnter) {
        return;
      }
      const payload = {
        cartSellID,
        cardID,
        number: cardNumEnter,
        pin: pinNumEnter,
        cards,
      };
      sendCardAndPinToServer(payload);
    }
    clearUpdateCardServerError(cardID);
  };

  render() {
    const {
      tradeBonus,
      cardID,
      cards,
      cashValue,
      number,
      pin,
      config,
    } = this.props;

    const { cardNumEnter, pinNumEnter } = cards[cardID];
    const currentCardValidation = cards && cards[cardID];

    let serverError = false;
    let cardAndPinMatch = false;
    let validationMessage = "";

    if (currentCardValidation && Object.keys(currentCardValidation).length) {
      serverError = currentCardValidation.serverError;
      cardAndPinMatch = currentCardValidation.match;
      validationMessage = currentCardValidation.msg;
    }

    let totalCardVal = cashValue;
    if (tradeBonus) {
      let valueToAdd;
      totalCardVal = Number(totalCardVal);
      valueToAdd = (tradeBonus / 100) * Number(cashValue);
      totalCardVal += valueToAdd;
      totalCardVal = totalCardVal + "";
    }

    const toggleCardNumActive = () => {
      this.setState((prevState) => ({
        cardNumActive: !prevState.cardNumActive,
      }));
    };
    const togglePinActive = () => {
      this.setState((prevState) => ({
        pinActive: !prevState.pinActive,
      }));
    };

    return (
      <>
        <Card>
          <Num>
            <input
              type="text"
              defaultValue={number}
              maxLength={30}
              onChange={(e) => {
                this.updateMerchNum(e.target.value.replace(/[\s\-]/g, ''));
              }}
              onKeyPress={(e) => {
                this.escapeSpecialChar(e);
              }}
              onFocus={() => toggleCardNumActive()}
              onBlur={() => toggleCardNumActive()}
              className="form-control"
              placeholder={config.cardNumberPlaceHolderText}
            />

            <Icon active={this.state.cardNumActive}>
              <svg
                width="16px"
                height="13px"
                viewBox="0 0 16 13"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Flow"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity={`${this.state.cardNumActive ? "1" : "0.300000012"}`}
                >
                  <g
                    id="icon"
                    transform="translate(-626.000000, -618.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                  >
                    <g id="Form" transform="translate(219.000000, 156.000000)">
                      <g
                        id="Group-2"
                        transform="translate(132.000000, 459.000000)"
                      >
                        <g
                          id="Group-5"
                          transform="translate(0.000000, 2.000000)"
                        >
                          <g
                            id="credit-card"
                            transform="translate(275.000000, 1.000000)"
                          >
                            <path
                              d="M0,9.75 C0,11.5449254 1.4326888,13 3.2,13 L12.8,13 C14.5673112,13 16,11.5449254 16,9.75 L16,5.95833333 L0,5.95833333 L0,9.75 Z M12.8,0 L3.2,0 C1.4326888,0 0,1.45507456 0,3.25 L16,3.25 C16,1.45507456 14.5673112,0 12.8,0 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Icon>
          </Num>

          <Pin>
            <input
              defaultValue={pin}
              type="text"
              maxLength={24}
              onChange={(e) => {
                this.updateMerchPin(e.target.value.replace(/[\s\-]/g, ''));
              }}
              onKeyPress={(e) => {
                this.escapeSpecialChar(e);
              }}
              onFocus={() => togglePinActive()}
              onBlur={() => togglePinActive()}
              className="form-control"
              placeholder={config.cardPinPlaceHolderText}
            />

            <Icon active={this.state.pinActive}>
              <svg
                width="12px"
                height="15px"
                viewBox="0 0 12 15"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Flow"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity={`${this.state.pinActive ? "1" : "0.300000012"}`}
                >
                  <g
                    id="icon"
                    transform="translate(-776.000000, -615.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                  >
                    <g id="Form" transform="translate(219.000000, 156.000000)">
                      <g
                        id="Group-2"
                        transform="translate(132.000000, 459.000000)"
                      >
                        <g
                          id="Group-5"
                          transform="translate(322.000000, 0.000000)"
                        >
                          <g
                            id="locked"
                            transform="translate(103.000000, 0.000000)"
                          >
                            <path
                              d="M10,7 L10,4 C10,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 L2,7 C0.8954305,7 0,7.8954305 0,9 L0,13 C0,14.1045695 0.8954305,15 2,15 L10,15 C11.1045695,15 12,14.1045695 12,13 L12,9 C12,7.8954305 11.1045695,7 10,7 Z M6,12.75 C5.31,12.75 4.75,11.965 4.75,11 C4.75,10.035 5.31,9.25 6,9.25 C6.69,9.25 7.25,10.035 7.25,11 C7.25,11.965 6.69,12.75 6,12.75 Z M8,7 L4,7 L4,4 C4,2.8954305 4.8954305,2 6,2 C7.1045695,2 8,2.8954305 8,4 L8,7 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Icon>
          </Pin>
          {config.layoutVersion === 3 && (
            <MobileErrors>
              <div
                className={
                  cardAndPinMatch === "PASSED" && serverError
                    ? "errorMsgr"
                    : "hide"
                }
              >
                {validationMessage}
              </div>
              <div
                className={
                  cardAndPinMatch !== "PASSED" && (cardNumEnter || pinNumEnter)
                    ? "errorMsg"
                    : "hide"
                }
              >
                Card and pin number not yet valid...
              </div>
            </MobileErrors>
          )}
        </Card>
        <Card>
          <Num>
            <DesktopErrors>
              <div
                className={
                  cardAndPinMatch === "PASSED" && serverError
                    ? "errorMsg"
                    : "hide"
                }
              >
                {validationMessage}
              </div>
              <div
                className={
                  cardAndPinMatch !== "PASSED" && (cardNumEnter || pinNumEnter)
                    ? "errorMsg"
                    : "hide"
                }
              >
                Card and pin number not yet valid...
              </div>
            </DesktopErrors>
          </Num>
        </Card>
      </>
    );
  }
}
