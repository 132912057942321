import { createSelector } from "reselect";
import { currencyFormatter } from "./utils/display";

import { widgetSelector } from "ccui-framework";
import { configSelector } from "ccui-framework";
export const cartSelector = (state) => state.cartSell;
export const merchantSelector = (state) => state.merchantsSell;
export const cardPartialSelector = (state) => state.cardPartial;
export const cardsSelector = (state) => state.cards;
export const sellVerificationSelector = (state) => state.sellVerification;
export const customerInfoSelector = (state) => state.customerInfo;
export const userNotificationsSelector = (state) => state.userNotifications;
export const userActionsSelector = (state) => state.userActions;
export const userInfoSelector = (state) => state.userInfo;
export const tloSelector = (state) => state.tlo;
export const cartIsEmptySelector = createSelector(
  cartSelector,
  (cart) => cart.cardIds.length === 0
);

export const merchantValuesSelector = createSelector(
  merchantSelector,
  (merchants) =>
    merchants.sortedByName.map((merch) => ({
      value: merch.id,
      label: merch.name,
      ...merch,
    }))
);

export const cardPartialMerchantIdSelector = createSelector(
  cardPartialSelector,
  (cardPartial) =>
    cardPartial.selectedMerchant ? cardPartial.selectedMerchant.id : null
);

export const enteredCardsValidateSelector = createSelector(
  cardsSelector,
  (cards) =>
    Object.keys(cards).some(
      (id) => cards[id].match !== "PASSED" || cards[id].serverError
    )
);

export const contactInfoValidateSelector = createSelector(
  sellVerificationSelector,
  (verification) =>
    !verification.get("email") ||
    verification.getIn(["errors", "email"]) ||
    !verification.get("phone") ||
    verification.getIn(["errors", "phone"])
);

export const creditCardValidateSelector = createSelector(
  customerInfoSelector,
  (customer) =>
    !customer.get("creditCardNum") ||
    !customer.get("ccv") ||
    !customer.get("month") ||
    !customer.get("year") ||
    customer.get("_monthError") ||
    customer.get("_yearError") ||
    customer.get("_ccvError") ||
    customer.get("_creditCardNumError")
);

export const adddressValidateSelector = createSelector(
  sellVerificationSelector,
  (verification) =>
    !verification.get("firstName") ||
    !verification.get("lastName") ||
    !verification.get("street") ||
    !verification.get("city") ||
    !verification.get("zip") ||
    !verification.get("state") ||
    verification.getIn(["errors", "firstName"]) ||
    verification.getIn(["errors", "lastName"]) ||
    verification.getIn(["errors", "street"]) ||
    verification.getIn(["errors", "city"]) ||
    verification.getIn(["errors", "state"]) ||
    verification.getIn(["errors", "zip"])
);

export const anyCardOrderTotalSelector = createSelector(
  widgetSelector,
  configSelector,
  (widgetData, config) =>
    Number(widgetData.payload.orderTotal) || config.testOrderTotal || 0
);

export const partnerOrderSelector = createSelector(
  cartSelector,
  customerInfoSelector,
  sellVerificationSelector,
  anyCardOrderTotalSelector,
  configSelector,
  widgetSelector,
  (cart, customer, verification, orderTotal, config, widget) => {
    const { email, phone, ...partnerData} = widget?.payload || {};
    return config.partnerBrand !== "Anycard"
      ? {
          autoSplit: false,
          cartId: cart.cartId,
          paymentMethod: "PARTNER",
          creditCardDetails: {
            save: false,
            number: customer.get("creditCardNum"),
            ccv: customer.get("ccv"),
            expMonth: customer.get("month"),
            expYear: customer.get("year"),
          },
          billingDetails: {
            firstname: verification.get("firstName"),
            lastname: verification.get("lastName"),
            street: verification.get("street"),
            street2: verification.get("street2") || "",
            city: verification.get("city"),
            state: verification.get("state"),
            postcode: verification.get("zip"),
          },
          customerDetails: {
            firstname: verification.get("firstName"),
            lastname: verification.get("lastName"),
            street: verification.get("street"),
            street2: verification.get("street2") || "",
            city: verification.get("city"),
            state: verification.get("state"),
            postcode: verification.get("zip"),
            email: verification.get("email"),
            phone: verification.get("phone"),
          },
          additionalInfo: Object.keys(partnerData).length ? partnerData : undefined
        }
      : {
          autoSplit: false,
          cartId: cart.cartId,
          paymentMethod: "ANYCARD",
          orderTotal: orderTotal,
          creditCardDetails: {
            save: false,
            number: customer.get("creditCardNum"),
            ccv: customer.get("ccv"),
            expMonth: customer.get("month"),
            expYear: customer.get("year"),
          },
          billingDetails: {
            firstname: verification.get("firstName"),
            lastname: verification.get("lastName"),
            street: verification.get("street"),
            street2: verification.get("street2") || "",
            city: verification.get("city"),
            state: verification.get("state"),
            postcode: verification.get("zip"),
          },
          customerDetails: {
            firstname: verification.get("firstName"),
            lastname: verification.get("lastName"),
            street: verification.get("street"),
            street2: verification.get("street2") || "",
            city: verification.get("city"),
            state: verification.get("state"),
            postcode: verification.get("zip"),
            email: verification.get("email"),
            phone: verification.get("phone"),
          },
        };
  }
);

export const isOrderIdEmptySelector = createSelector(
  userNotificationsSelector,
  (userNotifications) => userNotifications.orderID === null
);

export const cartTotalSelector = createSelector(
  cartSelector,
  cardsSelector,
  (cartSell, cards) => {
    let total =
      Object.keys(cartSell).length !== 0
        ? cartSell.cardIds.reduce((memo, value) => {
            return Number(memo) + Number(cards[value].cashValue);
          }, 0)
        : 0;

    return currencyFormatter(total);
  }
);

export const cartTotalsSelector = createSelector(
  cartSelector,
  cardsSelector,
  (cartSell, cards) => {
    return Object.values(cards).reduce((obj, card) => {
      obj.faceValue += Number(card.enterValue);
      obj.tradeValue += Number(card.cashValue);
      return obj;
    }, { faceValue: 0, tradeValue: 0 });
  }
);
