import React from "react";
import {
  checkBalance,
  changeButtonVal,
} from "../../utils/validators/stepOneSell";
import Select from "react-select";
import { score } from "../../utils/fuzzySearch";

export default class EachCard extends React.Component {
  changeButtonVal = () => {
    changeButtonVal(this.props, this.checkBalance);
  };
  checkBalance = () => checkBalance(this.props);
  handleEnter = (evt) => {
    if (evt.which === 13 || evt.keyCode === 13) {
      this.changeButtonVal();
    }
  };
  submitCardPartialMerchant = (merchant) => {
    const isEmptyArray = (arr) => Array.isArray(arr) && !arr.length;
    this.props.showNoCardPartialError();
    isEmptyArray(merchant)
      ? this.props.cardPartialMerchant("")
      : this.props.cardPartialMerchant(merchant);
  };
  filterOption = (merchant, text) =>
    !!text ? score(merchant.data, text) : true;

  render() {
    const {
      cardPartial,
      options,
      config,
      userActions,
      hideAddCardBox,
      cardPartialBalance,
      cards,
    } = this.props;

    const {
      balance,
      selectedMerchant,
      displayMerchantError,
      displayBalanceError,
      displayMaxError,
      displayMinError,
      displayCardBalanceFormatError,
    } = cardPartial;

    const merchantDropdownClasses = displayMerchantError
      ? "col-sm-7 select-container merchant-has-error"
      : "col-sm-7 select-container";
    const balanceInputClasses =
      displayBalanceError ||
      displayCardBalanceFormatError ||
      displayMinError ||
      displayMaxError
        ? "form-control balance-has-error"
        : "form-control";
    const submitBtnClasses =
      balance !== "" && selectedMerchant !== null
        ? "submitBtn btn btn-primary offer-contain activebtn"
        : "submitBtn btn btn-primary offer-contain";
    const submitBtnDisabled = balance === "" && selectedMerchant === null;

    const inputProps = {};

    let maxVal = "";
    let minVal = "";

    try {
      minVal = selectedMerchant.limit.min;
      maxVal = selectedMerchant.limit.max;
    } catch (err) {}

    if (config.balanceAsterisk) {
      inputProps.placeholder = "Balance *";
      inputProps.title = config.balanceAsterisk;
    }

    return (
      <div className="position-relative">
        {config.haveAnotherCardShowPopup &&
        Object.keys(cards).length &&
        Object.keys(cards).length < 2 &&
        userActions.get("displayAddCardBox") ? (
          <div className="arrow_box">
            <div className="close-btn" onClick={hideAddCardBox}>
              <div>x</div>
            </div>
            <div className="text-wrapper">
              <div
                className={
                  config.partnerBrand
                    ? "partner-arrow-box-title"
                    : "arrow-box-title"
                }
              >
                {config.haveAnotherCardTitle}
              </div>
              <div> {config.haveAnotherCardBody}</div>
            </div>
          </div>
        ) : null}
        <div id="appendme">
          <div className="row" style={{ margin: "0px 0px 15px 0px" }}>
            <div className={merchantDropdownClasses}>
              <Select
                filterOption={this.filterOption}
                placeholder={config.offerMerchantDropDownPlaceHolder}
                value={selectedMerchant}
                onChange={this.submitCardPartialMerchant}
                options={options}
                isClearable={true}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    marginTop: "0px",
                  }),
                  menuList: (styles) => ({
                    ...styles,
                    maxHeight: "12rem",
                    overflowX: "hidden",
                  }),
                }}
              />
              <div
                style={{ display: "flex" }}
                className={
                  displayMerchantError ? "errorMsg animated fadeIn" : "hide"
                }
              >
                Please select a merchant
              </div>
            </div>
            <span className="col-sm-2">
              <div className="input-icon">
                <i>$</i>
                <input
                  onBlur={this.checkBalance}
                  style={{ height: "36px" }}
                  value={balance}
                  onChange={cardPartialBalance}
                  onKeyPress={this.handleEnter}
                  type="text"
                  className={balanceInputClasses}
                  placeholder={config.offerBalanceInputPlaceHolder}
                  {...inputProps}
                />
              </div>
              <span
                className={
                  displayBalanceError
                    ? "errorMsg animated fadeIn left-align"
                    : "hide"
                }
              >
                Please enter a card value!
              </span>
              <span
                className={
                  displayMaxError
                    ? "errorMsg animated fadeIn left-align"
                    : "hide"
                }
              >
                ${maxVal} is the maximum allowed balance
              </span>
              <span
                className={
                  displayMinError
                    ? "errorMsg animated fadeIn left-align"
                    : "hide"
                }
              >
                ${minVal} is the minimum allowed balance
              </span>
              <span
                className={
                  displayCardBalanceFormatError
                    ? "errorMsg animated fadeIn left-align"
                    : "hide"
                }
              >
                Invalid balance
              </span>
            </span>
            <span className="col-sm-3 no-padding-sm">
              <input
                id="get-offer"
                type="button"
                onClick={this.changeButtonVal}
                name="submit"
                className={submitBtnClasses}
                value={
                  Object.keys(cards).length === 0
                    ? config.offerButtonTxt
                    : config.offerButtonTxtMany
                }
                disabled={submitBtnDisabled}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
