import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { closeWidget } from "ccui-framework";
import { customerInfoSelector, cartTotalsSelector, userNotificationsSelector } from "../../../selectors";

const BurgerWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.025rem;
  right: 1.5rem;
  top: 1.5rem;
`;

const BurgerLines = styled.div`
  transition: opacity 0.5s ease, transform 0.5s ease, top 0.5s ease,
    transform-origin 0.5s ease, width 0.5s ease, left 0.5s ease,
    border-radius 0.5s ease;
  transform: translateY(-50%);
  border-radius: 0.025rem;
  width: 80%;
  left: 10%;
  height: 10%;
  position: absolute;
  transform-origin: 50% 0%;
  background-color: #ffffff;
  opacity: 0.5;

  &:nth-of-type(1) {
    transform-origin: 50% 50%;
    top: 50%;
    transform: translate(0%, -50%) rotate(45deg);
  }

  &:nth-of-type(2) {
    top: 50%;
    opacity: 0;
  }

  &:nth-of-type(3) {
    top: 50%;
    transform-origin: 50% 50%;
    transform: translate(0%, -50%) rotate(-45deg);
  }
`;
export default () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const orderID = useSelector((state) => customerInfoSelector(state).get("orderID"));
  const orderKey = useSelector((state) => userNotificationsSelector(state)).orderID;
  const { faceValue, tradeValue } = useSelector((state) => cartTotalsSelector(state));
  const sellOrderPlacedSuccessfully = useSelector((state) => customerInfoSelector(state).get("sellOrderPlacedSuccessfully"));

  return (
    <>
      {config.variant === "widget" && (
        <BurgerWrapper
          onClick={() =>
            orderID && sellOrderPlacedSuccessfully ?
              dispatch(closeWidget({ orderID: `${orderID}`, orderKey, faceValue, tradeValue })) :
              dispatch(closeWidget({ error: "customer cancelled transaction." }))
          }
        >
          <BurgerLines />
          <BurgerLines />
          <BurgerLines />
        </BurgerWrapper>
      )}
    </>
  );
};
