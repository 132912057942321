import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import CCModal from "./modal";

import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { SET_ERROR } from "../../../constants/actionTypes";
import { defaultAction } from "../../../actions/defaultAction";

import DoubleArrowIcon from "./icons/doubleArrowIcon";
import BackArrow from "./icons/backArrow";
import CCLink from "./link";

const Container = styled.div`
  background-color: white;
  overflow: hidden;

  ${mediaPhone} {
    border-radius: 30px;
  }

  ${mediaDesktop} {
    border-radius: 30px 30px 0 30px;
  }
`;

const GoBack = styled.span`
  position: absolute;
  z-index: 1;
  cursor: pointer;

  ${mediaDesktop} {
    left: 4rem;
  }

  ${mediaPhone} {
    left: 1rem;
  }
`;

const Body = styled.div`
  ${({ scrollable }) => (scrollable ? "overflow-y: auto" : "")};
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "9rem")};
  ${mediaDesktop} {
    max-height: ${({ faq }) => (faq ? "29rem" : "19rem")};
  }

  ${mediaPhone} {
    max-height: ${({ faq }) => (faq ? "27rem" : "18rem")};
  }
`;

const ErrorBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px;
`;

const ErrorCrossContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 5px;
`;

const XIcon = styled.img`
  cursor: pointer;
`;

const ErrorData = styled.div`
  border-radius: 30px;
  box-shadow: rgba(61, 58, 53, 0.05) 0px 5px 20px 0px;
  background-color: rgb(255, 255, 255);
  padding: 4rem;

  p {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    margin: 0 auto;
    width: 90%;

    ${mediaPhone} {
      font-size: 12px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;

  ${mediaPhone} {
    height: 115px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaDesktop} {
    max-height: 150px;
    min-height: 95px;
  }
`;

const LearnMore = styled.div`
  ${mediaDesktop} {
    display: none;
  }

  ${mediaPhone} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${({ show }) => (show ? "50px" : "10px")};
    padding-bottom: ${({ show }) => (show ? "2rem" : "0rem")};
  }
`;

const TitleContainer = styled.div`
  ${mediaDesktop} {
    margin-bottom: 15px;
  }
`;

const Title = styled.h3`
  text-align: center;
  font-weight: 600;
  color: rgb(51, 67, 61);
  position: relative;
  top: -5px;

  ${mediaDesktop} {
    font-size: 21px;
  }

  ${mediaPhone} {
    margin-left: auto;
    margin-right: auto;
    font-size: 19px;
    width: 285px;
  }
`;

const ErrorHead = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border-radius: 30px 30px 0 0;
  align-items: center;

  img {
    margin: 1rem;
  }
`;

const LearnMoreLink = styled(CCLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  .double-arrow-icon {
    margin-left: 0.5rem;
    #Fill {
      fill: ${({ theme }) => theme.hyperlink_color};
    }
  }
`;

const Action = styled.div`
  ${({ actionShadow }) =>
    actionShadow ? "  box-shadow: 0 0 20px 0 rgba(61, 58, 53, 0.05);" : ""};
`;

const WarningIcon = styled.img`
  width: fit-content;
`;

const Copyright = styled.div`
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    opacity: 0.8;
    font-size: ${({ size }) =>
      size === "large" ? "18px" : size === "medium" ? "16px" : "14px"};
    line-height: 1.29;
    color: #4a4646;
  }

  img {
    width: ${({ size }) =>
      size === "large" ? "140px" : size === "medium" ? "120px" : "100px"};
    height: ${({ size }) =>
      size === "large" ? "35px" : size === "medium" ? "30px" : "25px"};
    padding-left: 5px;
  }
`;

const ActionContainer = ({
  title,
  goBack,
  scrollable,
  faq,
  children,
  minHeight,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const bodyRef = useRef(null);
  const [actionShadow, setActionShadow] = useState(false);
  const error = useSelector((state) => state.userNotifications.errorMsg);
  const config = useSelector((state) => state.config);
  const resetError = () => {
    dispatch(defaultAction(SET_ERROR, ""));
  };
  const showLearnMore = () => {
    return [
      "/",
      "/sounds-good",
      "/card-details",
      "/identity",
      "/identity-card-info",
    ].includes(location.pathname);
  };

  const showPoweredBy = () => {
    return [
      "/",
      "/sounds-good",
      "/card-details",
      "/identity",
      "/identity-card-info",
    ].includes(location.pathname);
  };

  useEffect(() => {
    if (
      bodyRef.current &&
      bodyRef.current.scrollHeight > bodyRef.current.clientHeight
    ) {
      setActionShadow(true);
    } else {
      setActionShadow(false);
    }
  }, [bodyRef]);

  return (
    <Container>
      {title && (
        <TitleContainer>
          {goBack && (
            <GoBack onClick={(e) => history.goBack()}>
              <BackArrow />
            </GoBack>
          )}
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </TitleContainer>
      )}
      <Body
        scrollable={scrollable}
        faq={faq}
        ref={bodyRef}
        minHeight={minHeight}
      >
        {children[0] || children}
      </Body>

      <CCModal isOpen={!!error && error !== ""}>
        <ErrorBody>
          <ErrorHead>
            <ErrorCrossContainer onClick={resetError}>
              <XIcon src="https://cdn.cardcash.com/website/partners/starbucks/close.png" />
            </ErrorCrossContainer>
            <WarningIcon src="https://cdn.cardcash.com/website/partners/common/error.svg" />
          </ErrorHead>

          <ErrorData>
            <p>{error}</p>
          </ErrorData>
        </ErrorBody>
      </CCModal>

      <Action actionShadow={actionShadow}>
        {children[1] && <Actions>{children[1]}</Actions>}

        <LearnMore show={showLearnMore()}>
          {showLearnMore() && (
            <LearnMoreLink to="/faq">
              Learn more <DoubleArrowIcon />
            </LearnMoreLink>
          )}
        </LearnMore>
      </Action>

      {config.variant === "widget" &&
        showPoweredBy() &&
        config.partnerBrand !== "Anycard" && (
          <Copyright size={config.poweredBySize}>
            <span> Powered By </span>
            <img src={config.headerLogoCCIcon} />
          </Copyright>
        )}
    </Container>
  );
};

ActionContainer.defaultProps = {
  goBack: false,
  scrollable: false,
  faq: false,
};

ActionContainer.propTypes = {
  goBack: PropTypes.bool,
  scrollable: PropTypes.bool,
  faq: PropTypes.bool,
  title: PropTypes.string,
  minHeight: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ActionContainer;
