import React, { Component } from "react";
import Select from "react-select";
import { currencyFormatter } from "../../utils/display";
import { score } from "../../utils/fuzzySearch";
import debounce from "../../utils/debounce";

export default class CardinCart extends Component {
  constructor(props) {
    super(props);
    this.updateTheBalance = debounce(this.updateTheBalance, 500);
  }

  filterOption = (merchant, text) =>
    !!text ? score(merchant.data, text) : true;

  updateTheBalance(amount) {
    this.changeEnterVal(amount);
  }

  checkIfBalanceError = (currentVal) => {
    const {
      cardID,
      merchantsSell,
      merchantID,
      cardInCartNoBalanceError,
      cardInCartMinError,
      cardInCartMaxError,
    } = this.props;

    currentVal = currentVal === "" ? 0 : Number(currentVal);

    if (currentVal === "" || currentVal === 0) {
      cardInCartNoBalanceError(cardID);
      return false;
    } else if (
      merchantsSell.hasOwnProperty(merchantID) &&
      currentVal > merchantsSell[merchantID].limit.max
    ) {
      cardInCartMaxError(cardID);
      return false;
    } else if (
      merchantsSell.hasOwnProperty(merchantID) &&
      currentVal < merchantsSell[merchantID].limit.min
    ) {
      cardInCartMinError(cardID);
      return false;
    } else {
      return true;
    }
  };

  changeEnterVal = (amount) => {
    if (this.checkIfBalanceError(amount)) {
      if (amount === "" || amount === ".") {
        return;
      }

      this.props.updateStepOneFields(
        this.props.cartSellID,
        this.props.cardID,
        this.props.merchantID,
        amount,
        this.props.cards
      );
    }
  };

  preventLetters = (evt) => {
    if (evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
    }
  };

  preventLettersAndDecimal = (el, evt) => {
    var getSelectionStart = function(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate();
        r.moveEnd("character", o.value.length);
        if (r.text == "") return o.value.length;
        return o.value.lastIndexOf(r.text);
      } else return o.selectionStart;
    };
    el = document.getElementById(this.props.cardID);

    var charCode = evt.which ? evt.which : event.keyCode;
    var number = el.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    }
    if (number.length > 1 && charCode == 46) {
      evt.preventDefault();
    }
    var caratPos = getSelectionStart(el);
    var dotPos = el.value.indexOf(".");

    if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
      evt.preventDefault();
    }
    return true;
  };

  changeMerchVal = (val) => {
    if (val.length <= 0) {
      return;
    }

    this.props.updateStepOneFields(
      this.props.cartSellID,
      this.props.cardID,
      val.id,
      this.props.enterVal,
      this.props.cards
    );
  };

  render() {
    const {
      options,
      merchantsSell,
      merchantID,
      deleteCardInCart,
      selected,
      enterVal,
      cardVal,
      cartSellID,
      cardID,
      index,
      cards,
      config,
    } = this.props;

    const merchantImage = merchantsSell.hasOwnProperty(merchantID)
      ? merchantsSell[merchantID].image
      : "";
    const minVal = merchantsSell.hasOwnProperty(merchantID)
      ? merchantsSell[merchantID].limit.min
      : "[Unknown]";
    const maxVal = merchantsSell.hasOwnProperty(merchantID)
      ? merchantsSell[merchantID].limit.max
      : "[Unknown]";

    let mailInReq = false;

    if (
      merchantsSell &&
      merchantsSell[selected.id] &&
      merchantsSell[selected.id].submissionMethod === "mail-in"
    ) {
      mailInReq = true;
    }

    return (
      <div className="card-in-cart">
        <div className="row" style={{ margin: "0px 0px 15px 0px" }}>
          <div className="col-sm-7 select-container">
            <span
              className="col-sm-1 merchant-img-container"
              style={{ paddingTop: "8px" }}
            >
              <img
                className="xStyle m_bottom_10"
                src="https://new-cardcash-images.s3.amazonaws.com/website/partner-remove-card-redx.png"
                width="18"
                onClick={() => deleteCardInCart(cartSellID, cardID, cards)}
              />
              <img
                className="respImg m_bottom_10"
                src={merchantImage}
                style={{ float: "left" }}
              />
            </span>
            <div
              className="select-container col-sm-11"
              style={{ paddingRight: "0px" }}
            >
              <Select
                filterOption={this.filterOption}
                value={selected}
                options={options}
                onChange={this.changeMerchVal}
                clearable={false}
              />
              <div
                style={{ display: "flex" }}
                className={
                  mailInReq ? "errorMsg animated fadeIn cc-def-color " : "hide"
                }
              >
                This card must be mailed in
              </div>
            </div>
          </div>
          <span className="col-sm-2">
            <input
              defaultValue={enterVal.toFixed(2)}
              style={{ height: "36px" }}
              onChange={(e) => {
                this.updateTheBalance(e.target.value);
              }}
              id={cardID}
              onKeyUp={this.formatTheInput}
              type="text"
              maxLength="255"
              className="form-control text-field enterTab"
              placeholder="Card Balance"
              onKeyPress={(evt) => this.preventLettersAndDecimal(this, evt)}
            />
            <span
              style={{ display: "flex", justifyContent: "flex-start" }}
              className={
                cards[cardID].balanceError === "NO_BALANCE_ERROR"
                  ? "errorMsg animated fadeIn"
                  : "hide"
              }
            >
              Please enter a card value!
            </span>
            <span
              style={{ display: "flex", justifyContent: "flex-start" }}
              className={
                cards[cardID].balanceError === "MAX_BALANCE_ERROR"
                  ? "errorMsg animated fadeIn"
                  : "hide"
              }
            >
              ${maxVal} is the maximum allowed balance
            </span>
            <span
              style={{ display: "flex", justifyContent: "flex-start" }}
              className={
                cards[cardID].balanceError === "MIN_BALANCE_ERROR"
                  ? "errorMsg animated fadeIn"
                  : "hide"
              }
            >
              ${minVal} is the minimum allowed balance
            </span>
          </span>
          <span className="col-sm-3 no-padding-sm">
            <div className="text-center offer-style">
              {cards[cardID].balanceError === "MIN_BALANCE_ERROR" ||
              cards[cardID].balanceError === "MAX_BALANCE_ERROR" ||
              cards[cardID].balanceError === "NO_BALANCE_ERROR"
                ? "Invalid Balance"
                : `${config.cardInCart_offerText}  ${currencyFormatter(
                    cardVal
                  )}`}
            </div>
          </span>
        </div>
      </div>
    );
  }
}
