import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ActionContainer from "../containers/actionContainer";
import Guard from "../containers/guard";
import InfoIcon from "../containers/icons/info";
import { closeWidget } from "ccui-framework";
import Button from "../containers/button";
import { defaultAction } from "../../../actions/defaultAction";
import {
  isOrderIdEmptySelector,
  cartTotalSelector,
  cartTotalsSelector,
  customerInfoSelector,
  userNotificationsSelector
} from "../../../selectors";

import {
  RESET_TWILIO_ATTEMPTS_EXCEEDED,
  COMPLETE_ORDER_SELL,
} from "../../../constants/actionTypes";

import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";

const Message = styled.div`
  ${mediaPhone} {
    margin: 0 auto 42px;
  }
  margin: 0 auto 15px;
  background-color: rgba(83, 92, 88, 0.05);
  border-radius: 18px;
  width: 90%;
  padding: 0 1rem;
  align-items: center;
  display: flex;

  p {
    font-size: 11px;
    display: flex;
  }
  .info-icon {
    margin-right: 1rem;
  }
`;

const ThankYou = styled.h1`
  font-weight: 600;
  text-align: center;

  ${mediaPhone} {
    font-size: 16px;
    margin-top: -118px;
  }
  ${mediaDesktop} {
    font-size: 21px;
    margin-top: -112px;
  }
`;

const Container = styled.div`
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  height: 19rem;
`;

const CustomIcon = styled.span`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
`;

const IconContainer = styled.div`
  height: 300px;
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.primary_color};
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaPhone} {
    margin-top: -20px;
  }
  ${mediaDesktop} {
    margin-top: -15px;
  }
`;

const Text = styled.p`
  text-align: center;

  ${mediaPhone} {
    font-size: 11px;
    margin-top: -7px;
  }
  ${mediaDesktop} {
    line-height: 1.71;
    margin-top: -5px;
    font-size: 11px;
  }
`;

const Action = styled.div`
  width: ${({ variant }) => (variant ? "85%" : "100%")};
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: ${({ variant }) => (variant ? "row" : "column")};
`;

const A = styled.a`
  background-color: ${({ theme }) => theme.button_background_color};
  color: ${({ theme }) => theme.button_font_color} !important;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-weight: bold;
  max-width: 437px;
  min-width: 50px;
  transition: background-color 0.5s ease 0s, border-color 0.5s ease 0s,
    border-width 0.1s ease 0s;
  outline: none;
  border-radius: 30px;
  border: rgba(83, 92, 88, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  box-sizing: border-box;
  ${mediaPhone} {
    margin-bottom: 8px;
  }
  ${mediaDesktop} {
    margin-bottom: 15px;
  }
`;

const Close = styled(Button)`
  width: 120px;
  height: 60px;
  border-radius: 30px;
  border: solid 1px rgba(51, 67, 61, 0.16);
  cursor: pointer;
  background: white;
  color: #33433d;
  margin-left: 10px;

  ${mediaPhone} {
    margin-bottom: 8px;
  }
  ${mediaDesktop} {
    margin-bottom: 15px;
  }
`;

export default () => {
  const dispatch = useDispatch();
  const { config, cartTotal, isOrderIdEmpty, orderID, orderKey, totals: { faceValue, tradeValue } } = useSelector(
    (state) => ({
      config: state.config,
      cartTotal: cartTotalSelector(state),
      isOrderIdEmpty: isOrderIdEmptySelector(state),
      orderID: customerInfoSelector(state).get("orderID"),
      orderKey: userNotificationsSelector(state).orderID,
      totals: cartTotalsSelector(state)
    }),
    shallowEqual
  );

  useEffect(() => {
    if (config.partnerBrand === "Amazon" || config.partnerBrand === "eBay") {
      let dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: `${config.partnerBrand.toLowerCase()}-trade`,
        orderNumber: orderID + "",
        transactionAffiliation: `${config.partnerBrand}`,
        transactionTotal: 1,
        transactionTax: 0,
        total: cartTotal,
      });
    }

    if (config.partnerBrand === "Anycard") {
      setTimeout(() => {
        dispatch(closeWidget({ orderID: `${orderID}` }));
      }, 5000);
    }

    return () => {
      dispatch(defaultAction(RESET_TWILIO_ATTEMPTS_EXCEEDED));
      dispatch(defaultAction(COMPLETE_ORDER_SELL));
    };
  }, [cartTotal, config.partnerBrand, dispatch, orderID]);

  return (
    <Guard condition={isOrderIdEmpty} redirect={"/"}>
      <ActionContainer>
        <React.Fragment>
          <Container>
            <IconContainer>
              {!config.orderCompleteCustomCheckMarkerIcon && (
                <Icon>
                  <svg
                    width="266px"
                    height="266px"
                    viewBox="0 0 266 266"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <circle id="path-1" cx="57" cy="57" r="57"></circle>
                      <filter
                        x="-127.2%"
                        y="-83.3%"
                        width="354.4%"
                        height="354.4%"
                        filterUnits="objectBoundingBox"
                        id="filter-3"
                      >
                        <feOffset
                          dx="0"
                          dy="50"
                          in="SourceAlpha"
                          result="shadowOffsetOuter1"
                        ></feOffset>
                        <feGaussianBlur
                          stdDeviation="40"
                          in="shadowOffsetOuter1"
                          result="shadowBlurOuter1"
                        ></feGaussianBlur>
                        <feColorMatrix
                          values="0 0 0 0 0.2   0 0 0 0 0.262745098   0 0 0 0 0.239215686  0 0 0 0.2 0"
                          type="matrix"
                          in="shadowBlurOuter1"
                        ></feColorMatrix>
                      </filter>
                    </defs>
                    <g
                      id="Flow"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="icon"
                        transform="translate(-436.000000, -388.000000)"
                      >
                        <g
                          id="Form"
                          transform="translate(219.000000, 156.000000)"
                        >
                          <g id="Rectangle">
                            <g
                              id="check"
                              transform="translate(293.000000, 258.000000)"
                            >
                              <g id="logo">
                                <mask id="mask-2" fill="white">
                                  <use xlinkHref="#path-1"></use>
                                </mask>
                                <g id="Mask-Copy">
                                  <use
                                    fill="black"
                                    fillOpacity="1"
                                    filter="url(#filter-3)"
                                    xlinkHref="#path-1"
                                  ></use>
                                  <use
                                    fill="#FFFFFF"
                                    fillRule="evenodd"
                                    xlinkHref="#path-1"
                                  ></use>
                                </g>
                                <g
                                  id="check-2"
                                  mask="url(#mask-2)"
                                  fill="currentColor"
                                >
                                  <g
                                    transform="translate(39.500000, 44.000000)"
                                    id="Path"
                                  >
                                    <path d="M13.088,15.45 L31.897,0.557 C32.9242814,-0.254100023 34.3952536,-0.171282745 35.325,0.75 C36.225,1.648 36.225,3.094 35.325,3.992 L14.635,24.616 C13.6860656,25.5559942 12.3867369,26.0554635 11.0525029,25.9931321 C9.71826895,25.9308007 8.47116518,25.3123698 7.614,24.288 L0.58,15.804 C-0.252127739,14.8038987 -0.181345833,13.3335457 0.743,12.418 C1.68055697,11.4894931 3.15512323,11.3833481 4.216,12.168 L8.738,15.518 C10.0375059,16.4786309 11.8191552,16.4507798 13.088,15.45 Z"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </Icon>
              )}
              {config.orderCompleteCustomCheckMarkerIcon && (
                <CustomIcon>
                  <img src={config.orderCompleteCustomCheckMarkerIcon} />
                </CustomIcon>
              )}
            </IconContainer>
            <ThankYou>Thank You!</ThankYou>
            <Text>{config.orderCompleteCardInfoTxt.text(config)}</Text>
          </Container>
        </React.Fragment>
        <React.Fragment>
          <Action variant={config.variant === "widget"}>
            {config.partnerBrand !== "Anycard" && (
              <A
                target={"_blank"}
                href={`${config.cardCashUrl}/order-status/${orderID}`}
              >
                {config.orderCompleteButtonTxt}
              </A>
            )}

            {config.partnerBrand === "Anycard" && (
              <A
                onClick={() => dispatch(closeWidget({ orderID: `${orderID}` }))}
              >
                {config.orderCompleteButtonTxt}
              </A>
            )}

            {config.variant === "widget" &&
              config.partnerBrand !== "Anycard" && (
                <Close
                  onClick={() =>
                    dispatch(closeWidget({ orderID: `${orderID}`, orderKey, faceValue, tradeValue }))
                  }
                >
                  Close
                </Close>
              )}
            {config.orderCompleteShowBonusTxt && (
              <Message>
                <InfoIcon />
                <p>{config.orderCompleteBonusTxt.text(config)}</p>
              </Message>
            )}
          </Action>
        </React.Fragment>
      </ActionContainer>
    </Guard>
  );
};
