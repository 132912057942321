import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { widgetSelector } from "ccui-framework";

import { EMAILREGEX, PHONEREGEX } from "../../constants/regex";
import styled from "@emotion/styled";
import { mediaPhone, mediaDesktop } from "../../layouts/styles/Breakpoints";

const Container = styled.div`
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  ${mediaPhone} {
    flex-direction: column;
    height: 4rem;
  }

  ${mediaDesktop} {
    flex-direction: row;
  }
`;

const Email = styled.input`
  flex: 1;
  ${mediaDesktop} {
    margin-right: 1rem;
  }
`;
const Phone = styled.input`
  flex: 1;

  ${mediaDesktop} {
    margin-left: 1rem;
  }
  ${mediaPhone} {
    margin-top: 30px;
  }
`;

const EmailDesktopError = styled.div`
  flex: 1;
  ${mediaDesktop} {
    margin-top: -10px;
  }

  ${mediaPhone} {
    display: none;
  }
`;

const PhoneDesktopError = styled.div`
  flex: 1;

  ${mediaDesktop} {
    margin-top: -10px;
    margin-left: 2rem;
  }

  ${mediaPhone} {
    display: none;
  }
`;

const EmailMobileError = styled.div`
  flex: 1;
  ${mediaDesktop} {
    display: none;
  }

  ${mediaPhone} {
    margin-top: -20px;
  }
`;

const PhoneMobileError = styled.div`
  flex: 1;
  ${mediaDesktop} {
    display: none;
  }

  ${mediaPhone} {
    margin-top: -20px;
  }
`;
const ContactInfo = ({
  store,
  config,
  sellVerification,
  customerInfo,
  hideEmailError,
  setEmail,
  hidePhoneError,
  setPhone,
  setEmailError,
  setPhoneError,
}) => {
  const {
    payload: { email, phone },
  } = useSelector((state) => widgetSelector(state));

  if (config.variant === "widget") {
    if (email) {
      setEmail(email);
    }

    if (phone) {
      setPhone(phone);
    }
  }

  const removeSpacesInEnteredPhone = (num) => num.match(/[0-9]+/g).join("");

  const checkPhone = (e) => {
    if (e.target.value.match(/[0-9]+/g) === null) {
      return false;
    }
    return PHONEREGEX.test(removeSpacesInEnteredPhone(e.target.value))
      ? true
      : false;
  };

  const formatPhone = (num) => {
    if (!num) {
      return num;
    }
    var numbers = num.replace(/\D/g, ""),
      char = { 0: "(", 3: ") ", 6: " - " };
    num = "";
    for (let i = 0; i < numbers.length; i++) {
      num += (char[i] || "") + numbers[i];
    }
    return num;
  };

  const checkEmail = (e) => {
    return EMAILREGEX.test(e.target.value) ? true : false;
  };

  const showInputs =
    store.payoutMethod === "PARTNER" ||
    store.payoutMethod === "DONATION" ||
    (config.partnerBrand && config.partnerBrand === "PayPal");
  const showEmailError = sellVerification.getIn(["errors", "email"]);
  const showPhoneNumError = sellVerification.getIn(["errors", "phone"]);

  return (
    <>
      <Container>
        {showInputs ? (
          <Email
            type="email"
            autoComplete="email"
            defaultValue={sellVerification.get("email")}
            onChange={(e) => {
              if (checkEmail(e)) {
                hideEmailError();
                setEmail(e.target.value);
              }

              if (!checkEmail(e) && !showEmailError) {
                setEmailError();
              }
            }}
            className="form-control"
            placeholder={config.emailAddressTextPlaceHolder}
          />
        ) : (
          `Email: ${customerInfo.get("email")}`
        )}
        <EmailMobileError>
          <p className={showEmailError ? " errorMsg" : "hide"}>
            Not a valid email address
          </p>
        </EmailMobileError>
        {showInputs ? (
          <Phone
            type="tel"
            autoComplete="tel"
            defaultValue={sellVerification.get("phone")}
            onChange={(e) => {
              if (checkPhone(e)) {
                hidePhoneError();
                setPhone(removeSpacesInEnteredPhone(e.target.value));
              }

              if (!checkPhone(e) && !showPhoneNumError) {
                setPhoneError();
              }
            }}
            className="form-control"
            placeholder={config.phoneNumTextPlaceHolder}
            maxLength={16}
          />
        ) : (
          `Phone: ${formatPhone(customerInfo.get("phone"))}`
        )}
        <PhoneMobileError>
          <p className={showPhoneNumError ? " errorMsg" : "hide"}>
            Not a valid phone number
          </p>
        </PhoneMobileError>
      </Container>
      <Container>
        <EmailDesktopError>
          <p className={showEmailError ? " errorMsg" : "hide"}>
            Not a valid email address
          </p>
        </EmailDesktopError>
        <PhoneDesktopError>
          <p className={showPhoneNumError ? " errorMsg" : "hide"}>
            Not a valid phone number
          </p>
        </PhoneDesktopError>
      </Container>
    </>
  );
};

ContactInfo.propTypes = {
  cartSell: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  sellVerification: PropTypes.object.isRequired,
  customerInfo: PropTypes.object.isRequired,
  hideEmailError: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  hidePhoneError: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  setEmailError: PropTypes.func.isRequired,
  setPhoneError: PropTypes.func.isRequired,
};

export default ContactInfo;
