import { Map } from "immutable";

import {
  UPDATE_CUSTOM_TWILIO_MSG,
  FIRE_EXTOLE_REGISTRATION,
  EXTOLE_REGISTRATION_COMPLETE,
  SEND_ECOMMERCE_INFO,
  SET_BUY_ORDER_UID,
  PUT_BITCOIN_INFO_IN_REDUX,
  SET_BUY_ORDER_ID,
  SHOW_DETAILS_ORDER,
  HIDE_DETAILS_ORDER,
  UPDATE_SEARCH_TEXT,
  TOGGLE_DETAILS_ORDER,
  DISPLAY_RECAPTCHA,
  SET_RECAPTCHA,
  DISPLAY_CHANGE_PHONE_ERROR,
  HIDE_CHANGE_PHONE_ERROR,
  SET_CHANGE_PHONE_TXT,
  UPDATE_ACCOUNT_STATUS,
  UPDATE_ATTEMPTS,
  UPDATE_PASS_CODE,
  SET_VERIFY_ID,
  SET_LAST_NAME,
  SET_FIRST_NAME,
  SHRINK_BOX,
  SET_SESSION_ID,
  SESSION_RENDER_ASSETS,
  ACCEPT_TERMS,
  DENY_TERMS,
  SET_STREET,
  SET_STREET2,
  SET_CITY,
  SET_ZIPCODE,
  SET_CREDIT_CARD,
  SELECT_MONTH,
  SELECT_YEAR,
  SELECT_STATE,
  SET_PHONE,
  SET_EMAIL,
  SET_CCV,
  SET_TOKEN,
  SET_SIGNIN_EMAIL,
  CLEAR_SET_SIGNIN_EMAIL,
  SET_SIGNIN_PASSWORD,
  CLEAR_SET_SIGNIN_PASSWORD,
  DISPLAY_SIGNIN_EMAIL_ERROR,
  HIDE_SIGNIN_EMAIL_ERROR,
  DISPLAY_SIGNIN_PASS_ERROR,
  DISPLAY_FIRST_NAME_ERROR,
  DISPLAY_LAST_NAME_ERROR,
  DISPLAY_STREET_ERROR,
  DISPLAY_STREET2_ERROR,
  DISPLAY_STATE_ERROR,
  DISPLAY_ZIP_ERROR,
  DISPLAY_EMAIL_ERROR,
  DISPLAY_PHONE_ERROR,
  DISPLAY_CREDIT_CARD_NUM_ERROR,
  DISPLAY_MONTH_ERROR,
  DISPLAY_YEAR_ERROR,
  DISPLAY_CCV_ERROR,
  DISPLAY_CITY_ERROR,
  HIDE_CITY_ERROR,
  HIDE_FIRST_NAME_ERROR,
  HIDE_LAST_NAME_ERROR,
  HIDE_SIGNIN_PASS_ERROR,
  HIDE_STREET_ERROR,
  HIDE_STREET2_ERROR,
  HIDE_STATE_ERROR,
  HIDE_ZIP_ERROR,
  HIDE_EMAIL_ERROR,
  HIDE_PHONE_ERROR,
  HIDE_CREDIT_CARD_NUM_ERROR,
  HIDE_MONTH_ERROR,
  HIDE_YEAR_ERROR,
  HIDE_CCV_ERROR,
  HIDE_ACH_ACCOUNT_NUM_ERROR,
  HIDE_ACH_ROUTING_NUM_ERROR,
  HIDE_ACH_ACCOUNT_TYPE_ERROR,
  DISPLAY_MAIN_PAYMENT_ERROR,
  DISPLAY_ACCEPT_TERMS_ERROR,
  HIDE_ACCEPT_TERMS_ERROR,
  HIDE_MAIN_PAYMENT_ERROR,
  SET_FORGOT_PASS_EMAIL,
  SET_ACH_SELL_ACCOUNT_NUM,
  SET_ACH_SELL_ROUTING_NUM,
  SET_ACH_SELL_ACCOUNT_TYPE,
  RESET_SELL_ACH,
  UPDATE_SAVE_ACH_SELL,
  SET_ORDER_ID,
  SELL_ORDER_SUCCESS,
  UPDATE_PAYPAL_SELL_INFO,
  DISPLAY_CUSTOMER_INFO_ERRORS,
  SET_CREDIT_CARD_TO_REMOVE,
  SET_CUSTOMER_PROFILE,
  CUSTOMER_PROFILE_THUNK_RUNNING,
  LOGOUT,
  LOGOUT_MARK_THUNK_RUNNING,
  LOGOUT_CLEAR_THUNK_RUNNING,
  RESET,
  RESET_BUY,
  RESET_EXPIRE,
  SET_BRAINTREE_TOKEN,
  SET_PAYMENT_OPTIONS,
  SHOW_ADDITIONAL_PAYMENT_OPTIONS,
  HIDE_ADDITIONAL_PAYMENT_OPTIONS,
  SELECT_PAYMENT_OPTION,
  SET_CUSTOMER_BANK_ACCOUNTS,
  COMPLETE_ORDER_SELL,
} from "../constants/actionTypes";

const initialState = Map({
  firstname: "",
  _firstnameError: false,
  lastname: "",
  _lastnameError: false,
  street: "",
  _streetError: false,
  street2: "",
  _street2Error: false,
  city: "",
  _cityError: false,
  state: "",
  _stateError: false,
  zip: "",
  _zipError: false,
  email: "",
  _emailError: false,
  phone: "",
  changePhoneTxt: "",
  _phoneError: false,
  _phoneErrorModal: false,
  twilioVerify: false,
  creditCardNum: "",
  _creditCardNumError: false,
  month: "",
  _monthError: false,
  year: "",
  _yearError: false,
  ccv: "",
  _ccvError: false,
  achNum: "",
  _achNumErr: false,
  routingNum: "",
  _routingNumErr: false,
  acctType: "",
  _acctTypeErr: false,
  mainPaymentError: false,
  bitcoinPaymentId: "",
  acceptTerms: false,
  _acceptTermsError: false,
  token: null,
  signInEmail: null,
  signInPassword: null,
  invalidSignInEmail: false,
  invalidSignInPassword: false,
  forgotPassEmail: null,
  signedIn: false,
  forgotPassword: null,
  orderID: null,
  orderIDBuy: null,
  orderUIDBuy: null,
  orderIDShow: null,
  sessionID: null,
  renderAssets: false,
  verifyID: null,
  passCode: null,
  accountStatus: "",
  attempts: "0",
  round: 1,
  customTwilioMsg: "",
  showRecaptcha: false,
  recaptchaCode: null,
  showSendAnotherCodeBtn: false,
  mainErrorMsg: "Please correct the errors below and try again.",
  passErrorMsg: "Invalid Password",
  keepAliveError: false,
  showDetailsOrder: true,
  searchTxt: "",
  creditCards: null,
  bankAccounts: null,
  customerBankAccounts: [],
  achAuthorized: false,
  bulkSeller: false,
  ecommerceInfoSent: false,
  creditCardToRemove: null,
  defaultPaymentOption: {},
  _logoutThunkRunning: false,
  _customerInfoThunkRunning: false,
  braintreeToken: "",
  fireRegisterExtole: false,
  paymentOptions: {},
  showAdditionalPaymentOptions: false,
  selectedPaymentOptionId: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CUSTOM_TWILIO_MSG:
      return state.set("customTwilioMsg", action.payload);

    case FIRE_EXTOLE_REGISTRATION:
      return state.set("fireRegisterExtole", true);

    case EXTOLE_REGISTRATION_COMPLETE:
      return state.set("fireRegisterExtole", false);

    case SEND_ECOMMERCE_INFO:
      return state.set("ecommerceInfoSent", true);

    case SET_BUY_ORDER_UID:
      return state.set("orderUIDBuy", action.payload);

    case PUT_BITCOIN_INFO_IN_REDUX:
      return state
        .set("orderUIDBuy", action.payload.orderUIDBuy)
        .set("bitcoinPaymentId", action.payload.paymentId);

    case SET_BUY_ORDER_ID:
      return state.set("orderIDBuy", action.payload);

    case SHOW_DETAILS_ORDER:
      return state.set("showDetailsOrder", true);

    case HIDE_DETAILS_ORDER:
      return state.set("showDetailsOrder", false);

    case UPDATE_SEARCH_TEXT:
      return state.set("searchTxt", action.payload);

    case TOGGLE_DETAILS_ORDER:
      return state.set("showDetailsOrder", !state.get("showDetailsOrder"));

    case DISPLAY_RECAPTCHA:
      return state.set("showRecaptcha", true);

    case SET_RECAPTCHA:
      return state.set("recaptchaCode", action.payload);

    case DISPLAY_CHANGE_PHONE_ERROR:
      return state.set("_phoneErrorModal", true);

    case HIDE_CHANGE_PHONE_ERROR:
      return state.set("_phoneErrorModal", false);

    case SET_CHANGE_PHONE_TXT:
      return state.set("changePhoneTxt", action.payload);

    case UPDATE_ACCOUNT_STATUS:
      return state.set("accountStatus", action.payload);

    case UPDATE_ATTEMPTS:
      return state.set("attempts", action.payload);

    case UPDATE_PASS_CODE:
      return state.set("passCode", action.payload);

    case SET_VERIFY_ID:
      return state.set("verifyID", action.payload);

    case SHRINK_BOX:
      return state.set("forgotPassword", null);

    case SET_SESSION_ID:
      return state.set("sessionID", action.payload);

    case SESSION_RENDER_ASSETS:
      return state.set("renderAssets", action.payload);

    case SET_FIRST_NAME:
      return state.set("firstname", action.payload);

    case SET_LAST_NAME:
      return state.set("lastname", action.payload);

    case ACCEPT_TERMS:
      return state.set("acceptTerms", true);

    case DENY_TERMS:
      return state.set("acceptTerms", false);

    case SET_STREET:
      return state.set("street", action.payload);

    case SET_STREET2:
      return state.set("street2", action.payload);

    case SET_CITY:
      return state.set("city", action.payload);

    case SET_ZIPCODE:
      return state.set("zip", action.payload);

    case SET_CREDIT_CARD:
      return state.set("creditCardNum", action.payload);

    case SELECT_MONTH:
      return state.set("month", action.payload);

    case SELECT_YEAR:
      return state.set("year", action.payload);

    case SELECT_STATE:
      return state.set("state", action.payload);

    case SET_PHONE:
      return state.set("phone", action.payload);

    case SET_EMAIL:
      return state.set("email", action.payload);

    case SET_CCV:
      return state.set("ccv", action.payload);

    case SET_TOKEN:
      return state.set("token", action.payload);

    case SET_SIGNIN_EMAIL:
      return state.set("signInEmail", action.payload);

    case CLEAR_SET_SIGNIN_EMAIL:
      return state.set("signInEmail", "");

    case SET_SIGNIN_PASSWORD:
      return state.set("signInPassword", action.payload);

    case CLEAR_SET_SIGNIN_PASSWORD:
      return state.set("signInPassword", "");

    case DISPLAY_SIGNIN_EMAIL_ERROR:
      return state.set("invalidSignInEmail", true);

    case HIDE_SIGNIN_EMAIL_ERROR:
      return state.set("invalidSignInEmail", false);

    case DISPLAY_SIGNIN_PASS_ERROR:
      return state
        .set("invalidSignInPassword", true)
        .set("passErrorMsg", action.payload);

    case DISPLAY_FIRST_NAME_ERROR:
      return state.set("_firstnameError", true);

    case DISPLAY_LAST_NAME_ERROR:
      return state.set("_lastnameError", true);

    case DISPLAY_STREET_ERROR:
      return state.set("_streetError", true);

    case DISPLAY_STREET2_ERROR:
      return state.set("_street2Error", true);

    case DISPLAY_STATE_ERROR:
      return state.set("_stateError", true);

    case DISPLAY_ZIP_ERROR:
      return state.set("_zipError", true);

    case DISPLAY_EMAIL_ERROR:
      return state.set("_emailError", true);

    case DISPLAY_PHONE_ERROR:
      return state.set("_phoneError", true);

    case DISPLAY_CREDIT_CARD_NUM_ERROR:
      return state.set("_creditCardNumError", true);

    case DISPLAY_MONTH_ERROR:
      return state.set("_monthError", true);

    case DISPLAY_YEAR_ERROR:
      return state.set("_yearError", true);

    case DISPLAY_CCV_ERROR:
      return state.set("_ccvError", true);

    case DISPLAY_CITY_ERROR:
      return state.set("_cityError", true);

    case HIDE_CITY_ERROR:
      return state.set("_cityError", false);

    case HIDE_FIRST_NAME_ERROR:
      return state.set("_firstnameError", false);

    case HIDE_LAST_NAME_ERROR:
      return state.set("_lastnameError", false);

    case HIDE_SIGNIN_PASS_ERROR:
      return state.set("invalidSignInPassword", false);

    case HIDE_STREET_ERROR:
      return state.set("_streetError", false);

    case HIDE_STREET2_ERROR:
      return state.set("_street2Error", false);

    case HIDE_STATE_ERROR:
      return state.set("_stateError", false);

    case HIDE_ZIP_ERROR:
      return state.set("_zipError", false);

    case HIDE_EMAIL_ERROR:
      return state.set("_emailError", false);

    case HIDE_PHONE_ERROR:
      return state.set("_phoneError", false);

    case HIDE_CREDIT_CARD_NUM_ERROR:
      return state.set("_creditCardNumError", false);

    case HIDE_MONTH_ERROR:
      return state.set("_monthError", false);

    case HIDE_YEAR_ERROR:
      return state.set("_yearError", false);

    case HIDE_CCV_ERROR:
      return state.set("_ccvError", false);

    case HIDE_ACH_ACCOUNT_NUM_ERROR:
      return state.set("_achNumErr", false);

    case HIDE_ACH_ROUTING_NUM_ERROR:
      return state.set("_routingNumErr", false);

    case HIDE_ACH_ACCOUNT_TYPE_ERROR:
      return state.set("_acctTypeErr", false);

    case DISPLAY_MAIN_PAYMENT_ERROR:
      return state
        .set("mainPaymentError", true)
        .set("keepAliveError", true)
        .set("mainErrorMsg", action.payload);

    case DISPLAY_ACCEPT_TERMS_ERROR:
      return state.set("_acceptTermsError", true);

    case HIDE_ACCEPT_TERMS_ERROR:
      return state.set("_acceptTermsError", false);

    case HIDE_MAIN_PAYMENT_ERROR:
      return state.set("mainPaymentError", false);

    case SET_FORGOT_PASS_EMAIL:
      return state.set("forgotPassEmail", action.payload);

    case SET_ACH_SELL_ACCOUNT_NUM:
      return state.set("achNum", action.payload);

    case SET_ACH_SELL_ROUTING_NUM:
      return state.set("routingNum", action.payload);

    case SET_ACH_SELL_ACCOUNT_TYPE:
      return state.set("acctType", action.payload);

    case RESET_SELL_ACH:
      return state
        .set("achId", null)
        .set("achNum", "")
        .set("routingNum", "")
        .set("acctType", "");

    case UPDATE_SAVE_ACH_SELL:
      return state
        .set("achId", action.payload.id)
        .set("achNum", action.payload.accountNumber)
        .set("routingNum", action.payload.routingNumber)
        .set("acctType", action.payload.accountType);

    case SET_ORDER_ID:
      return state.set("orderID", action.payload.id);

    case SELL_ORDER_SUCCESS:
      return state.set("sellOrderPlacedSuccessfully", true);

    case UPDATE_PAYPAL_SELL_INFO:
      return state
        .set("internalPaymentId", action.payload.internalPaymentId)
        .set("paymentId", action.payload.paymentId);

    case DISPLAY_CUSTOMER_INFO_ERRORS:
      return state
        .set("_firstnameError", action.payload._firstNameErr)
        .set("_lastnameError", action.payload._lastNameErr)
        .set("_streetError", action.payload._streetErr)
        .set("_phoneError", action.payload._phoneErr)
        .set("_zipError", action.payload._zipErr)
        .set("_cityError", action.payload._cityErr)
        .set("_stateError", action.payload._stateErr)
        .set("_creditCardNumError", action.payload._creditCardNumErr)
        .set("_acceptTermsError", action.payload._acceptTermsErr)
        .set("_emailError", action.payload._emailErr)
        .set("_monthError", action.payload._monthErr)
        .set("_yearError", action.payload._yearErr)
        .set("_achNumErr", action.payload._achNumErr)
        .set("_routingNumErr", action.payload._routingNumErr)
        .set("_acctTypeErr", action.payload._acctTypeErr)
        .set("_ccvError", action.payload._ccvErr);

    case SET_CREDIT_CARD_TO_REMOVE:
      return state.set("creditCardToRemove", action.payload);

    case SET_CUSTOMER_PROFILE:
      const { profileData } = action.payload;

      return state
        .set("id", profileData.customer.id)
        .set("firstname", profileData.customer.firstname)
        .set("lastname", profileData.customer.lastname)
        .set("email", profileData.customer.email)
        .set("phone", profileData.customer.phone[0].number.toString())
        .set("twilioVerify", profileData.customer.twillioVerify) // CAUTION returned property has too many L's
        .set("state", profileData.customer.address[0].state)
        .set("city", profileData.customer.address[0].city)
        .set("zip", profileData.customer.address[0].postcode)
        .set("street", profileData.customer.address[0].street)
        .set("street2", profileData.customer.address[0].street2)
        .set("signedIn", true)
        .set("creditCards", profileData.customer.creditCards)
        .set("bankAccounts", profileData.customer.bankAccounts)
        .set("achAuthorized", profileData.customer.achAuthorized)
        .set("bulkSeller", profileData.customer.bulkSeller)
        .set(
          "defaultPaymentOption",
          profileData.customer.defaultPaymentOption.sell
        )
        .set("_firstnameError", false)
        .set("_lastnameError", false)
        .set("_emailError", false)
        .set("_phoneError", false)
        .set("_stateError", false)
        .set("_cityError", false)
        .set("_zipError", false)
        .set("_streetError", false);

    case CUSTOMER_PROFILE_THUNK_RUNNING:
      return state.set("_customerInfoThunkRunning", action.payload);

    case LOGOUT:
      return state
        .set("signedIn", false)
        .set("creditCards", null)
        .set("firstname", "")
        .set("lastname", "")
        .set("email", "")
        .set("phone", "")
        .set("twilioVerify", "")
        .set("state", "")
        .set("city", "")
        .set("zip", "")
        .set("street", "");

    case LOGOUT_MARK_THUNK_RUNNING:
      return state.set("_logoutThunkRunning", true);

    case LOGOUT_CLEAR_THUNK_RUNNING:
      return state.set("_logoutThunkRunning", false);

    case RESET_EXPIRE:
    case COMPLETE_ORDER_SELL:
    case RESET:
      return initialState;

    case RESET_BUY:
      return state.set("orderUIDBuy", null);

    case SET_BRAINTREE_TOKEN:
      return state.set("braintreeToken", action.payload);

    case SET_PAYMENT_OPTIONS:
      return state.set("paymentOptions", action.payload);

    case SHOW_ADDITIONAL_PAYMENT_OPTIONS:
      return state.set("showAdditionalPaymentOptions", true);

    case HIDE_ADDITIONAL_PAYMENT_OPTIONS:
      return state
        .set("showAdditionalPaymentOptions", false)
        .set("selectedPaymentOptionId", null);

    case SELECT_PAYMENT_OPTION:
      return state.set("selectedPaymentOptionId", action.payload);

    case SET_CUSTOMER_BANK_ACCOUNTS:
      return state.set("customerBankAccounts", action.payload);

    default:
      return state;
  }
};
